import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
export default function Col1() {
  const [src, setSrc] = useState('');
  const [muted, setMuted] = useState(true);
  const [show, setShow] = useState(false);
  const playVideo = (e) => {
    setSrc(e);
    setShow(true);
    setTimeout(() => {
      setMuted(false);
    });
  };
  const closeVideo = () => {
    setShow(false);
    setMuted(true);
  };
  return (
    <>
      {show ? (
        <div className="videoMask">
          <CloseOutlined className="close" onClick={() => closeVideo()} />
          <video src={src} autoPlay loop controls muted={muted}></video>
        </div>
      ) : (
        ''
      )}
      <div className="center aboutcont">
        <Swiper
          modules={[Autoplay, Navigation]}
          spaceBetween={20}
          navigation={true}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <img
              src="/static/about/swiper/5.jpg"
              alt=""
              style={{ width: '100%', height: '300px', objectFit: 'cover' }}
            />
          </SwiperSlide>
          {/* <SwiperSlide>
            <img
              src="/static/about/swiper/2.jpg"
              alt=""
              style={{ width: '100%', height: '300px', objectFit: 'cover' }}
            />
          </SwiperSlide> */}
          <SwiperSlide>
            <img
              src="/static/about/swiper/3.jpg"
              alt=""
              style={{ width: '100%', height: '300px', objectFit: 'cover' }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="/static/about/swiper/4.jpg"
              alt=""
              style={{ width: '100%', height: '300px', objectFit: 'cover' }}
            />
          </SwiperSlide>
        </Swiper>
        {/* <img
          src="/static/img/1.jpg"
          alt=""
          style={{ width: '100%', height: '300px', objectFit: 'cover' }}
        /> */}
        <p>
          萧山图灵小镇命名源于计算机、人工智能之父——艾伦·麦席森·图灵，寓意是围绕计算机技术和人工智能方向发展的特色小镇。小镇于2017年首次公开亮相，2018年入选第三批省级特色小镇培育名单，2019年入选第五批省级特色小镇创建名单，2020年成功入选“杭州数字经济旅游十景”，2022年正式获评国家3A级旅游景区，并正式命名为第六批浙江省特色小镇。
        </p>
        <p>
          图灵小镇位于钱江世纪城核心板块，规划面积3.25平方公里，其中核心区域1平方公里，由景观生态区、产业集聚区、数字文创区三部分构成，聚集天人大厦、望廷中心、ITC归谷国际中心等商业楼宇35栋。以数字经济为特色，小镇着力构建"3+6+X"的产业生态体系，围绕人工智能、大数据、云计算3大数字技术，重点突出金融科技、虚拟现实、无人驾驶、互联网+、未来试听、高性能计算6大产业发展，打造智慧亚运、智慧会展、智慧音乐等X个应用场景。
        </p>
        <p>
          目前，小镇集聚了商汤科技，易现先进科技，传化智联等行业龙头企业，北大信研院、浙大研究院等高水平科研院所，浙商银行，省交通集团金融中心等金融科技类重点项目，中国（杭州）5G创新谷、ITC归谷国际中心、全球顶级孵化器Founders Space等产业创新服务平台，构成数字经济产业及创新人才高质量发展生态圈。
        </p>
        <p>
          在空间布局上，小镇着力打造“一带、一路、一圈、三片区”的空间结构。“一带”即钱江世纪公园打造的1.8公里江滨景观带；“一路”即沿市心北路，形成的2.8公里产业中央创新路；“一圈”即在一带和一路交汇处，形成聚集以“人工智能+展览展示”为核心的智能生态圈；“三片区”即形成ABC产业集聚区、数字文创区、景观生态区三大片区。
        </p>
        <p>
          未来，小镇将持续围绕“产业更特、创新更强、功能更优、形态更美、机制更活、辐射更广”的2.0版城市化特色小镇建设目标，加快推进“产、城、人、文”深度融合，着力建成具有高能级、强辐射、新活力的生态都市核心区典型示范。
        </p>
      </div>
      <div className="mt30 pt20">
        <div className="text-center mb20">
          <h2 className="subt">景区视频</h2>
          <p>Park Videos</p>
        </div>
        <div className="center videos flex align-center justify-center text-center">
          <div>
            <a
              onClick={() =>
                playVideo(
                  'https://qiniu-cjyz.ninewe.com/%E5%9B%BE%E7%81%B5%E5%AE%A3%E4%BC%A0%E7%89%871.mp4'
                )
              }
            ></a>
            <img src="/static/img/play.png" className="play" />
          </div>
          <div>
            <a
              onClick={() =>
                playVideo(
                  'https://ztai-vow.ninewe.com/%E9%92%B1%E6%B1%9F%E4%B8%96%E7%BA%AA%E5%9F%8E%E6%8B%9B%E5%95%86%E8%A7%86%E9%A2%91%E5%AE%A3%E4%BC%A0%E7%89%87%20(online-video-cutter.com).mp4'
                )
              }
            />
            <img src="/static/img/play.png" className="play" />
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Autoplay,Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper from 'swiper/dist/js/swiper.js';
// import 'swiper/dist/css/swiper.css';

import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
import './Home.css';
import './Home.scss';
import "swiper/css/navigation";
import { WOW } from 'wowjs';
import Blobity from 'blobity';
import { getCompanyList, getCateList, getBanner } from '../../js/api';

const options = {
  color: 'rgb(180, 180, 180)',
  size: 10,
  opacity: 0.2,
  zIndex: -1,
};
new Blobity(options);
export default function Home() {
  var navigate = useNavigate();
  const todetail = (e, v) => {
    e.preventDefault();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    navigate('/newsdetail', {
      state: {
        content: v,
      },
    });
  };

  const [companyList, setCompanyList] = useState([]);
  const [active, setActive] = useState(0);
  const [content, setContent] = useState('');
  const [newsList, setNewsList] = useState([]);
  const [notice, setNotice] = useState([]);
  const [swiper, setSwiper] = useState([]);
  const [dotactive, setDotActive] = useState([]);
  const [swiperBanner, setSwiperBanner] = useState([]);
  const [enterpriseList, setEnterpriseList] = useState([]); //企业
  const [platformList, setPlatformList] = useState([]); //平台
  const banner = async () => {
    let res = await getBanner();
    setSwiperBanner(res.data);
  };
  var dotClick = (active) => {
    setCompanyList([]);
    setDotActive(active);
  };
  var navto = (title) => {
    navigate('/news', {
      state: {
        cate: title,
      },
    });
  };
  const to = (e) => {
    navigate(`/${e}`);
  };
  const toDetail = (v) => {
    console.log(v);
    navigate('/servicedetail', {
      state: {
        content: v,
      },
    });
  };
  const activity = (v) => {
    navigate('/activity', {
      state: {},
    });
  };
  const enter = (v) => {
    navigate('/enter', {
      state: {},
    });
  };

  var init = async () => {
    let enterprise = await getCompanyList({
      page: 0,
      size: 100,
      type: '企业',
    });
    setEnterpriseList(enterprise.data);
    let platform = await getCompanyList({
      page: 0,
      size: 100,
      type: '平台',
    });
    setPlatformList(platform.data);
  };

  var tabClick = (index, category) => {
    setActive(index);
    getCateList({
      page: 0,
      size: 100,
      category,
    }).then((res) => {
      if (!res.error_code) {
        if (!res.data.length) {
          setContent(
            <p style={{ textAlign: 'center', padding: '40px 0' }}>暂无数据</p>
          );
        } else {
          setContent('');
        }
        setNewsList(res.data);
      }
    });
  };
  useEffect(() => {
    banner();
  }, []);

  useEffect(() => {
    tabClick(0, '小镇动态');
    getCateList({
      page: 0,
      size: 5,
      needCover: true,
    }).then((res) => {
      if (!res.error_code) {
        setSwiper(res.data);
      }
    });
  }, []);

  useEffect(() => {
    const wow = new WOW({
      live: false,
      boxClass: 'com',
    });
    wow.init();
    init();
  }, [platformList.length]);

  useEffect(() => {
    const wow = new WOW({
      live: false,
      boxClass: 'dash',
    });
    wow.init();
    getCateList({
      page: 0,
      size: 10,
      category: '通知公告',
    }).then((res) => {
      if (!res.error_code) {
        setNotice(res.data);
      }
    });
  }, [notice.length]);
  return (
    <>
      <div className="center swiper">
        <Swiper
          modules={[Autoplay,Navigation]}
          spaceBetween={20}
          navigation={true}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {swiperBanner.map((v, i) => {
            return (
              <SwiperSlide>
                <img src={v} className="banner" alt="" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="center flex align-center justify-between mt30  announcement">
        <img src="/static/img/announcement.png" alt="" className="l" />
        <div class="r">
          {/* {notice.map((v, i) => {
            return (
              <div
                key={i}
                onClick={(e) => todetail(e, v)}
                style={{ marginLeft: i == 1 ? '40px' : '0' }}
                className="item"
              >
                {v.title}
              </div>
            );
          })} */}
          <Swiper
            className="swiper-no-swiping"
            modules={[Autoplay]}
            direction={"vertical"}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
          >
            {notice.map((v, i) => {
              return (
                <SwiperSlide >
                  <div
                    key={i}
                    onClick={(e) => todetail(e, v)}
                    className="item"
                  >
                    {v.title}
                  </div>
                </SwiperSlide>


              );
            })}

          </Swiper>
          <a onClick={() => navto('通知公告')} className="more">更多公告<img src="/static/img/trun-right.png" alt="" /></a>
        </div>
        {/* <div className="flex align-center ">
          <span style={{ marginRight: '10px' }}>公示公告:</span>

        </div>
*/}
      </div>
      <div className="center content">
        <div className="news">
          <div className="title">
            <div>
              {localStorage.getItem('locale') === 'zh' ? '小镇新闻' : 'News / ニュース / 뉴스'}
            </div>
            <a onClick={() => navto('小镇动态')} className="more">更多新闻<img src="/static/img/trun-right.png" alt="" /></a>
          </div>
          <div className="list">
            {swiper.map((v, i) => {
              return (
                <a className="item" key={i} onClick={(e) => todetail(e, v)}>
                  <img src={v.cover} />
                  <div className="bot">
                    <div className="bot_title">{v.title}</div>
                    {i === 0 ? (
                      <div className="bot_time">{v.updateTime}</div>
                    ) : (
                      ''
                    )}
                  </div>
                </a>
              );
            })}
          </div>
        </div>
        <div className="convenient">
          <div className="title">
            <div>{localStorage.getItem('locale') === 'zh' ? '便捷入口' : 'Entrance / エントリ / 입구'}</div>
          </div>
          <div className="list">
            <a className="item" onClick={() => toDetail('工商服务')}>
              <div className="icon"/>
              <div className="text">工商服务</div>
            </a>
            <a className="item" onClick={() => toDetail('人力资源')}>
              <div className="icon"/>
              <div className="text">人力资源</div>
            </a>
            <a className="item" onClick={() => toDetail('法律咨询')}>
              <div className="icon"/>
              <div className="text">法律咨询</div>
            </a>
            <a className="item" onClick={() => toDetail('财税咨询')}>
              <div className="icon"/>
              <div className="text">财税咨询</div>
            </a>
            <a className="item" onClick={() => toDetail('投诉建议')}>
              <div className="icon"/>
              <div className="text">品牌推广</div>
            </a>
            <a className="item" onClick={() => enter()}>
              <div className="icon"/>
              <div className="text">企业入驻</div>
            </a>
            <a className="item" onClick={() => activity()}>
              <div className="icon"/>
              <div className="text">政策直达</div>
            </a>

            <a className="item" onClick={() => toDetail('知识产权')}>
              <div className="icon"></div>
              <div className="text">知识产权</div>
            </a>

            <a className="item" onClick={() => to('service')}>
              <div className="icon"></div>
              <div className="text">更多</div>
            </a>
          </div>
        </div>
      </div>
      {/* 活动 */}
      {/* <div className="center activity">
        <div className="title">
          <div>活动</div>
          <a onClick={() => navto('活动')}>&lt;&lt;更多</a>
        </div>
        <div className="list">
          <a className="item">
            <div className="status">已结束</div>
            <div className="tips">致公明志讲堂·创客法律服务专题活动</div>
            <div className="img"></div>
            <div className="bottom">
              <div className="time">11.22 14:00</div>
              <div className="address">杭州市</div>
            </div>
          </a>
        </div>
      </div> */}
      {/* <div className="center news mt20">
        <div className="mainnews">
          <div className="sn">
            <span></span>
            <a className={active===0?'active':''} onClick={()=>tabClick(0,'小镇动态')}>小镇动态</a>
            <a className={active===1?'active':''} onClick={()=>tabClick(1,'科技资讯')}>科技资讯</a>
            <a className={active===2?'active':''} onClick={()=>tabClick(2,'企业动态')}>企业动态</a>
            <a className={active===3?'active':''} onClick={()=>tabClick(3,'近期活动')}>近期活动</a>
          </div>
        </div>
      </div> */}
      {/* {
        <>
          {
            newsList.length?
            <div className="center flex">
              <div className="mainnews flex-sub">
                <div className="dash wow bounceInLeft">
                  <a className="mt30 h3" onClick={(e) => todetail(e, newsList[0])}>{newsList[0].title}</a>
                  <p>{newsList[0].description}</p>
                </div>
              </div>
              <ul className="newslist flex-sub dash wow bounceInRight">
                {
                  newsList.slice(1,4).map((v,i) => {
                    return (
                      <li key={i} onClick={(e) => todetail(e, v)}>
                        <a href={v.link}>{v.title}</a>
                      </li>
                    )
                  })
                }
              </ul>
            </div> : content
          }
          <div className="swiperwrap center mt20" style={{display:swiper.length?'block':'none'}}>
            <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={20}
              slidesPerView={3}
              navigation
            >
              {swiper.map((v, i) => {
                return (
                  <SwiperSlide key={i} onClick={(e) => todetail(e, v)}>
                    <div
                      className="items dash wow bounceInUp"
                      style={{ animationDelay: i * 0.3 + 's' }}
                    >
                      <div className="imgwrap">
                        <img
                          src={v.cover}
                          alt=""
                        />
                      </div>
                      <p>{v.title}</p>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="text-center mt30 mb30">
              <button className="more">更多</button>
            </div>
          </div>
        </>
      }       */}
      {/* <div className="quick mt20">
        <div className="center">
          <div className="tit">
            <h3 style={{ paddingTop: '20px', display: 'block' }}>快捷入口</h3>
            <p>Quick Access</p>
          </div>
        </div>
        <div className="flex justify-center align-center center enter quickene quickList">
          <div className="dash wow bounceInLeft ">
            <Link to="/enter">
              <div></div>
              <p>参观预约</p>
            </Link>
          </div>
          <div
            className="dash wow bounceInLeft"
            style={{ animationDelay: '.5s' }}
          >
            <Link to="/service">
              <div></div>
              <p>企业服务</p>
            </Link>
          </div>
          <div
            className="dash wow bounceInLeft"
            style={{ animationDelay: '1s' }}
          >
            <Link to="/enter">
              <div></div>
              <p>活动报名</p>
            </Link>
          </div>
        </div>
      </div> */}
      <div className="center comp">
        <div className="text-center mb20">
          <h2 className="subt">{localStorage.getItem('locale') === 'zh' ? '图灵入驻平台' : 'Platform / プラットフォーム / 플랫폼'}</h2>
          <p>They're in Turing Town</p>
        </div>
        <ul className="flex flex-wrap">
          {platformList.map((v, i) => {
            return (
              <li
                key={i}
                className={platformList.length ? 'com wow bounceInUp' : ''}
                style={{
                  animationDelay: i * 0.1 + 's',
                }}
              >
                <a href={v.link} target="_blank" rel="noreferrer">
                  <div className="img">
                    <img src={v.image} alt={v.name} />
                  </div>
                  <div className="name">{v.name}</div>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="center comp">
        <div className="text-center mb20">
          <h2 className="subt">{localStorage.getItem('locale') === 'zh' ? '图灵入驻企业(部分)' : 'Enterprise / エンタープライズ / 기업'}</h2>
          <p>They're in Turing Town</p>
        </div>
        <ul className="flex  flex-wrap">
          {enterpriseList.map((v, i) => {
            return (
              <li
                key={i}
                className={enterpriseList.length ? 'com wow bounceInUp' : ''}
                style={{
                  animationDelay: i * 0.1 + 's',
                }}
              >
                <a href={v.link} target="_blank" rel="noreferrer">
                  <div className="img">
                    <img src={v.image} alt={v.name} />
                  </div>
                  <div className="name">{v.name}</div>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      {/* <div className="dot">
        {new Array(Math.ceil(companyList.length / 20)).fill(0).map((v, i) => {
          return (
            <span
              key={i}
              onClick={() => dotClick(i)}
              className={dotactive == i ? 'active' : ''}
            ></span>
          );
        })}
      </div> */}
    </>
  );
}

import React from 'react';
import './Service.css';
export default function ServiceCenter() {
  return (
    <>
      <div className="header_img">
        <img
          className="header_bg"
          style={{ width: '100%', height: '300px', objectFit: 'cover' }}
          src="/static/header/企业服务.jpg"
          alt=""
        />
        <img
          src="/static/header/企业服务.png"
          alt=""
          className="header_title"
        ></img>
      </div>
      <div className="center">
        <div style={{ marginTop: '40px' }}>
          <div className="center-title-1 text-center">
            <h2>一站式企服中心</h2>{' '}
          </div>{' '}
        </div>
        <div style={{ marginTop: '40px' }}></div>

        <img src={require('./image/1.png')} className="paragraphImage" />
        <div className="paragraph">
          为精准、高效地服务好小镇内数量庞大的企业，助力企业健康、持续、稳定地发展，强化小镇数字经济产业链，在钱江世纪城管委会的指导下，图灵小镇携手萧山国际人才服务中心，设立图灵小镇一站式企业服务中心，以营造一流营商环境、提升企业服务质量为目标，想企业所想、急企业所急、解企业所困，为小镇企业及人才提供“一揽子服务”。
        </div>
        <div className="paragraph">
          图灵小镇一站式企业服务中心已与108家知名服务机构达成合作，服务内容涵盖包括工商服务、财税服务、法律服务、人才服务、金融服务、商务服务等在内的12大类130类细项服务，并构建了全生态的“5+3”服务体系，即通过入驻指引与协同服务、政策服务、人才服务、金融服务、品牌推广服务五大重点服务内容，为企业构建起“创新支持-发展规划-品牌打造”的全链式服务体系，帮助企业快速树立创新优势、发展优势和品牌优势，推动企业快速成长。
        </div>
        <div className="paragraph">
          截至2022年3月，图灵小镇一站式企业服务中心已发放企业服务清单2000余份，累计受理线上线下咨询超385次；协助指导科技型企业完成项目申报、资质申请等相关认定，办结率和满意率均达100%；开展“企业体检”，完成线上调研500余家、实地走访327家，实际解决企业切身问题60余项；组织开展科技政策、国高申报、人才认定等线上线下培训会20余次，惠及企业600余家；开设“一企看图灵”助企宣发栏目，为商汤科技、碳银互联网等数字经济科创企业提供专题专访服务15次，并及时发布企业荣誉及获奖资讯等，帮助企业提升品牌形象。
        </div>
        <div className="paragraph">
          图灵小镇将始终坚持“细心、用心、贴心”的三心服务理念，深化“最多跑一次”改革，专业、高效地帮助小镇企业解决其发展过程中面临的痛点难点，为企业发展赋能增效。同时，小镇将紧紧围绕“
          亚运国际城，数智新萧山”的发展导向，持续发挥超强“磁力”，营造更优营商环境，吸引全球优秀的人才和企业来到小镇创新、发展和落地，推动世纪城构建优质高效的创新生态圈，为萧山擦亮“数智”、“开放”的金名片助力。
        </div>
        <img src={require('./image/2.jpg')} className="paragraphImage" />
        <img src={require('./image/3.jpg')} className="paragraphImage" />
        <img src={require('./image/4.jpg')} className="paragraphImage" />
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import { Autoplay } from 'swiper';
import { message } from 'antd';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import './Service.css';
import { getServiceList } from '../../js/api';

export default function Service() {
  var navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [service, setService] = useState({ swiper: { images: [] } });

  const tabClick = (tabIndex) => {
    setActive(tabIndex);
  };
  const toDetail = (i, v) => {
    navigate('/servicedetail', {
      state: {
        content: v.title,
      },
    });
  };
  const serviceClick = (i, v) => {
    console.log(v);
    if (v.link) window.open(v.link);
    else message.info('暂未上线，准备中！');
  };

  const init = () => {
    getServiceList({
      page: 0,
      size: 100,
    }).then((res) => {
      if (!res.error_code) {
        var entry = res.data
          .filter((v) => v.name.indexOf('entry') == 0)
          .sort((a, b) => {
            return a.name.split('-')[1] - b.name.split('-')[1];
          });
        var topic = res.data.filter((v) => v.name.indexOf('topic') == 0);
        var window = res.data.filter((v) => v.name.indexOf('window') == 0);
        var pic = res.data.filter((v) => v.name.indexOf('pic') == 0);
        var swiper = res.data.find((v) => v.name == 'swiper');
        setService({
          entry,
          topic,
          window,
          pic,
          swiper: swiper,
        });
      }
    });
  };

  useEffect(() => {
    init();
  }, [Object.keys(service).length]);

  return (
    <>
      <div className="header_img">
        <img
          className="header_bg"
          style={{ width: '100%', height: '300px', objectFit: 'cover' }}
          src="/static/header/企业服务.jpg"
          alt=""
        />
        <img src="/static/header/企业服务.png" alt="" className="header_title"></img>
      </div>
      <div className="Engbackdrop-title">
        <span className="title-text">基础服务</span>
        <span className="title-bg">JI CHU FU WU</span>
      </div>
      <ul className="keyword-lists center" id="grfw">
        {service.entry &&
          service.entry.map((v, i) => {
            return (
              <li key={i} onClick={() => toDetail(i, v)}>
                <a target="_blank" rel="noreferrer">
                  <img className="keyword-icon" src={v.image} />
                  <img className="keyword-icon-hover" src={v.image} />
                  <span>{v.title}</span>
                </a>
              </li>
            );
          })}
      </ul>
      <div className="Engbackdrop-title">
        <span className="title-text">特色服务</span>
        <span className="title-bg">TE SE FU WU</span>
      </div>
      <div className="swiperwrap2 center">
        {service.window &&
          service.window.map((v, i) => {
            return (
              <div className="items2" key={i}>
                <a onClick={() => serviceClick(i, v)}>
                  <img src={v.image} alt={v.name} />
                </a>
              </div>
            );
          })}
      </div>

      <ul className="linkbar_index center">
        {service.pic &&
          service.pic.map((v, i) => {
            return (
              <li key={i}>
                <a target="_blank" href={v.link} rel="noreferrer">
                  <img src={v.image} alt={v.name} />
                </a>
              </li>
            );
          })}
      </ul>

      <div
        className="common-content center clearfix flex justify-between"
        style={{ marginBottom: '60px' }}
      >
        <div>
          <Swiper
            className="miniswiper"
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={20}
            slidesPerView={1}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            loop
            pagination={{ clickable: true }}
          >
            {service.swiper.images &&
              service.swiper.images.map((v, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div className="items2">
                      <a
                        href={service.swiper.links[i]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={v} alt="" />
                      </a>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
        <div className="subjectbox pull-right">
          <ul className="subject-tabbar">
            <li
              className={active == 0 ? 'active' : ''}
              onClick={() => tabClick(0)}
            >
              大家都在问
            </li>
            {/* <li
              className={active == 1 ? 'active' : ''}
              onClick={() => tabClick(1)}
            >
              服务推荐
            </li> */}
          </ul>
          <div
            className="subject-content group-row-2"
            style={{ display: active == 0 ? 'block' : 'none' }}
          >
            {service.topic &&
              service.topic.slice(0, 4).map((v, i) => {
                return (
                  <a key={i} target="_blank" href={v.link} rel="noreferrer">
                    <span className="subject-item-til ellipsis">{v.title}</span>
                    <span className="subject-item-intro ellipsis">
                      {v.subTitle}
                    </span>
                  </a>
                );
              })}
          </div>
          <div
            className="subject-content group-row-2"
            style={{ display: active == 1 ? 'block' : 'none' }}
          >
            {service.topic &&
              service.topic.slice(4).map((v, i) => {
                return (
                  <a key={i} target="_blank" href={v.link} rel="noreferrer">
                    <span className="subject-item-til ellipsis">{v.title}</span>
                    <span className="subject-item-intro ellipsis">
                      {v.subTitle}
                    </span>
                  </a>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import { range } from 'lodash';
import './show.scss';
import { Autoplay } from 'swiper';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getCateList, adBanner1, adBanner2 ,adBanner3} from '../../js/api';
import { CloseCircleOutlined } from '@ant-design/icons';
import 'swiper/css';
const ZHOU = [
  '星期日',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
];

export default function Show() {
  var navigate = useNavigate();
  let list = [
    { title: '领导关怀' },
    { title: '小镇概况', path: 'about' },
    { title: '浏览路线', path: 'enter', sel: 2 },
    { title: '周边推荐', path: 'enter', sel: 3 },
    { title: '小镇活动', path: 'activity' },
    { title: '出行交通' },
    { title: '企业服务', path: 'service' },
    { title: '展厅预约', path: 'enter' },
    { title: '投诉建议' },
  ];
  const [s, setS] = useState('');
  const [day, setDay] = useState('');
  const [zhou, setZhou] = useState('');
  const [now, setNow] = useState({});
  const [img, setImg] = useState('');
  const [notice, setNotice] = useState([]);
  const [banner1, setBanner1] = useState([]);
  const [banner2, setBanner2] = useState([]);
  const [banner3, setBanner3] = useState([]);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');

  useEffect(() => {
    getCateList({
      page: 0,
      size: 10,
      category: '通知公告',
    }).then((res) => {
      if (!res.error_code) {
        setNotice(res.data);
      }
    });
  }, [notice.length]);

  useEffect(async () => {
    let res = await adBanner1();
    setBanner1(res.data);
  }, [banner1.length]);
  useEffect(async () => {
    let res = await adBanner2();
    setBanner2(res.data);
  }, [banner2.length]);
  useEffect(async () => {
    let res = await adBanner3();
    setBanner3(res.data);
  }, [banner3.length]);
  const to = (e) => {
    if (e.title === '领导关怀' || e.title === '出行交通') {
      setShow(true);
      setTitle(e.title);
    } else if (e.title === '浏览路线' || e.title === '周边推荐') {
      navigate(`/${e.path}`, {
        state: {
          content: e.sel,
        },
      });
    } else if (e.title === '投诉建议') {
      navigate('/servicedetail', {
        state: {
          content: '投诉建议',
        },
      });
    } else {
      if (e.path) navigate(`/${e.path}`);
    }
  };
  const close = () => {
    setShow(false);
  };
  let getWeather = () => {
    let url = `https://api.seniverse.com/v3/weather/now.json?key=SF_LlwC6c4HrlJlIk&location=hangzhou&language=zh-Hans&unit=c`;
    axios({
      url: url,
      method: 'get',
    }).then((res) => {
      //   console.log(res.data.results[0].now);
      setNow(res.data.results[0].now);
      if (['0', '1', '2', '3'].includes(now.code)) {
        setImg('sun');
      } else if (
        ['10', '11', '12', '13', '14', '15', '16', '17', '18'].includes(
          now.code
        )
      ) {
        setImg('rain');
      } else if (['20', '21', '22', '23', '24', '25'].includes(now.code)) {
        setImg('snow');
      } else {
        setImg('cloudy');
        console.log(111);
      }
    });
  };

  useEffect(() => {
    setInterval(() => {
      const date = dayjs();
      const sound = date.format('HH:mm');
      const day = date.format('YYYY-MM-DD');
      const time = date.day();
      setZhou(ZHOU[time]);
      setS(sound);
      setDay(day);
    }, 1000);
    getWeather();
    setInterval(() => {
      getWeather();
    }, 1000 * 60 * 60);
    return () => {};
  }, []);
  return (
    <>
      <div className="show">
        <div className="s_t">
          <Swiper
            modules={[Autoplay]}
            spaceBetween={20}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
          >
            {banner1.map((v, i) => {
              return (
                <SwiperSlide key={i}>
                  <img src={v} className="banner" alt="" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="s_c">
          <Swiper
            modules={[Autoplay]}
            spaceBetween={20}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
          >
            {banner2.map((v, i) => {
              return (
                <SwiperSlide key={i}>
                  <img src={v} className="banner" alt="" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="s_b">
          <div className="l">
            <img src="/static/show/logo.png" alt="" className="logo" />
            <div className="date">
              {day} {zhou}
            </div>
            <div className="time">{s}</div>
            <div className="weather">
              <div className="icon">
                <img src={`/static/show/${img}.png`} alt="" />
              </div>
              <div className="info">
                <div className="city">杭州</div>
                <div className="temperature">{now.text}</div>
                <div className="temperature">{now.temperature}℃</div>
              </div>
            </div>
            <div class="code_list">
              <div className="item">
                <div className="img">
                  <img src="/static/img/xzgw.png" alt="" />
                </div>
                <span>小镇官网</span>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/static/img/wxgzh.jpg" alt="" />
                </div>
                <span>微信公众号</span>
              </div>
              <div className="item">
                <div className="img">
                  <img src="/static/img/wxcode.png" alt="" />
                </div>
                <span>景区导览</span>
              </div>
            </div>

            <div className="phone">
              咨询电话（0571）：<div>82640098 / 82201950</div>
            </div>
            <div className="phone">
              投诉电话（0571）：<div>82201959</div>
            </div>
            <div className="phone">
              救援电话（0571）：<div>83529876 / 82217010</div>
            </div>
          </div>
          <div className="r">
            <div className="information">
              <div className="information_l">文旅快讯</div>
              <div className="information_r">
                {notice && notice.length > 0 ? (
                  <marquee>
                    {notice.map((v, i) => {
                      return (
                        <a key={i} style={{ marginRight: '40px' }}>
                          [{v.createTime.split(' ')[0]}] {v.title}
                        </a>
                      );
                    })}
                  </marquee>
                ) : (
                  ''
                )}
              </div>
            </div>
            <img src="/static/show/bg.png" alt="" className="r_bg" />
            <div className="list">
              {list.map((el, index) => {
                return (
                  <div className="item" key={index} onClick={() => to(el)}>
                    <img src={`/static/show/${el.title}.png`} alt="" />
                    <div className="title">{el.title}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="imgMask" style={{ display: show ? 'block' : 'none' }}>
      <img
            src="/static/img/back.png"
            alt=""
            className="back"
            onClick={() => close()}
          ></img>
        {/* <CloseCircleOutlined
          className="close"
          onClick={() => close()}
          style={{ color: title === '出行交通' ? 'white' : '' }}
        /> */}
        <Swiper
          modules={[Autoplay]}
          spaceBetween={20}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          style={{ display: title === '领导关怀' ? 'block' : 'none' }}
        >
          {banner3.map((v, i) => {
            return (
              <SwiperSlide key={i}>
                <img src={v} alt="" />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <img
          src="/static/img/cxjt.png"
          alt=""
          className="cxjt"
          style={{ display: title === '出行交通' ? 'block' : 'none' }}
        ></img>
      </div>
    </>
  );
}

import React, { useEffect, useState,useRef } from 'react';
import Nav from './components/Nav/Nav';
import Footer from './components/Footer/Footer';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import './App.css';
import './css/reset.css';
import 'animate.css';
import 'antd/dist/antd.css';
import intl from 'react-intl-universal';
const locales = {
  en: require('./locales/en-US.json'),
  zh: require('./locales/zh-CN.json'),
};

export default function App() {
  var navigate = useNavigate();
  var [y, setY] = useState(-200);
  var [initDone, setInitDone] = useState(false);
  let [width, setWidth] = useState(0);
  let loadLocales = () => {
    // react-intl-universal 是单例模式, 只应该实例化一次
    intl
      .init({
        currentLocale:
          localStorage.getItem('locale') ||
          localStorage.getItem('defaultLng') ||
          'zh',
        locales,
      })
      .then(() => {
        setInitDone(true);
      });
  };

  function useClick(callback, doubleCallback) {
    const clickRef = useRef({
      clickCount: 0,
      time: 0,
      timer: null,
    });
    return (...args) => {
      clickRef.current.clickCount += 1;
      clickRef.current.time = Date.now();
      clickRef.current.timer = setTimeout(() => {
        if (
            Date.now() - clickRef.current.time <= 200 &&
            clickRef.current.clickCount === 2
        ) {
          doubleCallback && doubleCallback.apply(null, args);
        }
        if (clickRef.current.clickCount === 1) {
          callback && callback.apply(null, args);
        }
        clearTimeout(clickRef.current.timer);
        clickRef.current.clickCount = 0;
      }, 200);
    };
  };

  const onLabelClick = useClick(
      () => {
        window.history.back(-1)

      },
      () => {
        navigate('/show');
      }
  );


  useEffect(() => {
    loadLocales();
    setWidth(document.documentElement.scrollWidth);
    document.body.onresize = () => {
      console.log(document.documentElement.scrollWidth);
      setWidth(document.documentElement.scrollWidth);
    };
    document.body.onmousemove = (event) => {
      var e = event || window.event;
      var scrollTop = document.documentElement.scrollTop;
      setY(scrollTop + e.clientY);
    };
    // document.body.
  });
  const to = () => navigate('/show');

  return (
    <>
      <div className="warp">
        <div className="circuitBg"></div>
        <div className="circuitBg circuitBg1"></div>
        <div className="circuitBg circuitBg2"></div>
        <div className="circuitBg circuitBg3"></div>
        <div className="circuit" style={{ top: y + 'px' }}></div>
        <div className="circuit circuit2" style={{ top: y + 'px' }}></div>
        {width < 1100 && width > 1060 ? (
          <img
            src="/static/img/back.png"
            alt=""
            className="back"
            onClick={() => onLabelClick()}
          ></img>
        ) : (
          ''
        )}
        <Nav></Nav>
        <Outlet></Outlet>
        <Footer></Footer>
      </div>
    </>
  );
}

import React from 'react'
import './Footer.scss'

export default function Footer() {
  return (
    <>
      <footer>
      <div className="footer-top">
      <img src="/static/footer/bg.png" alt=""  />
      </div>
        <div className="footer center">

          <div className="l">
            <img src="/static/img/code.jpeg" alt=""  />
            <p className="tac mt10">图灵小镇公众号</p>
          </div>
          <div className="r">
            <div className="list">
              景区联系方式 Contact:
              咨询电话(Information):0571-82640098|救援电话(Complaint)
              0571-82201950
            </div>
            <div className="list">
              舆情监控台 Public monitoring : 站点安全服务：已开启 |
              搜索引擎监控：已开启 | 实时监控：已开启 | 安全访问人数：14028人
            </div>
            <div className="list">
              友情链接：
              <a href="https://www.mct.gov.cn/"> 中华人民共和国文化和旅游部 </a>|
              <a href="http://ct.zj.gov.cn/"> 浙江省文化和旅游厅 </a>|
              <a href="http://www.qjcbd.com/"> 钱江世纪城 </a>|
              <a href="https://www.ctrip.com"> 携程 </a>
              2021 萧山图灵小镇版权所有  <a href="https://beian.miit.gov.cn/">浙ICP备19008479号-3</a>
            </div>
          </div>
        </div>
        {/* <div className="center">
          <div className="flex justify-between">
            <div style={{ width: '140px' }} className="code">
              <img
                src="/static/img/code.jpeg"
                alt=""
                style={{ width: '140px' }}
              />
              <p className="tac mt10">图灵小镇公众号</p>
            </div>
            <div className="card">
              <div className="item">
                <h4>景区联系方式</h4>
                <p className="mt5 fz12">
                  <span>Contact</span>
                </p>
                <h4 className="mt20">
                  咨询电话
                  <span>
                    <span className="normal fz12">(Information)</span>
                  </span>
                </h4>
                <p className="mt15">0571-82640098</p>
                <h4 className="mt15">
                  救援电话
                  <span>
                    <span className="normal fz12">(Complaint)</span>
                  </span>
                </h4>
                <p className="mt15">0571-82201950</p>
              </div>
              <div className="item">
                <h4>舆情监控台</h4>
                <p className="mt5 fz12">
                  <span>Public monitoring</span>
                </p>
                <h4 className="mt20">
                  站点安全服务：
                  <span className="green">已开启</span>
                </h4>
                <h4 className="mt15">
                  搜索引擎监控：
                  <span className="green">已开启</span>
                </h4>
                <h4 className="mt15">
                  实时监控：
                  <span className="green">已开启</span>
                </h4>
                <h4 className="mt15">
                  安全访问人数：
                  <span>14028人</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="mt40 link">
            <p className=" mb10">
              友情链接：
              <a href="https://www.mct.gov.cn/">中华人民共和国文化和旅游部</a>|
              <a href="http://ct.zj.gov.cn/">浙江省文化和旅游厅</a>|
              <a href="http://www.qjcbd.com/">钱江世纪城</a> |
              <a href="https://www.ctrip.com">携程</a>
            </p>
            <p>
              <span>2021 萧山图灵小镇版权所有.浙ICP备18043303号-1</span>
            </p>
          </div>
        </div> */}
      </footer>
    </>
  )
}

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Service.css';
export default function ServiceProblem() {
  const location = useLocation();
  let id = location.search.split('=')[1];
  const [info, setInfo] = useState({});

  useEffect(() => {
    let obj = {};
    if (id == 1) {
      obj = {
        title: '科技型中小企业，2022年研发费用加计扣除比例是多少?',
        content:
          '<p>根据《财政部税务总局科技部关于进一步提高科技型中小企业研发费用税前加计扣除比例的公告》(财政部税务总局科技部公告2022年第16号)规定:“一、科技型中小企业开展</p><p>研发活动中实际发生的研发费用，未形成无形资产计入当期损益的，在按规定据实扣除的基础上，自2022年1月1日起，再按照实际发生额的100%在税前加计扣除;形成无形资产的，自2022年1月1日起，按照无形资产成本的200%在税前摊销。”        </p>',
      };
    } else if (id == 2) {
      obj = {
        title: '小微企业税费支持政策？',
        content:
          '<p>根据《关于实施小微企业和个体工商户所得税优惠政策的公告》（财政部 税务总局公告2021年第12 号）、《关于进一步实施小微企业所得税优惠政策的公告》（财政部 税务总局公告2022年第13号）规定，加大小型微利企业所得税减免力度。</p><p>自2021年1月1日至2022年12月31日，对小型微利企业年应纳税所得额不超过100万元的部分，减按12.5%计入应纳税所得额，按20%的税率缴纳企业所得税。</p><p>自2022年1月1日至2024年12月31日，对小型微利企业年应纳税所得额超过100万元但不超过300万元的部分，减按25%计入应纳税所得额，按20%税率缴纳企业所得税。          </p>',
      };
    } else if (id == 3) {
      obj = {
        title: '公司设立登记需要哪些材料?',
        content: `
        <h3>公司设立登记需要哪些材料?</h3>
        <ul>
            <li>公司登记（备案）申请书</li>
            <li>公司章程</li>
            <li>股东、发起人的主体资格证明或自然人身份证明</li>
            <li>法定代表人、董事、监事和经理的任职文件</li>
            <li>住所使用证明</li>
            <li>募集设立的股份有限公司提交依法设立的验资机构出具的验资证明。涉及发起人首次出资是非货币财产的，提交已办理财产权转移手续的证明文件</li>
            <li>募集设立的股份有限公司公开发行股票的应提交国务院证券监督管理机构的核准文件</li>
            <li>法律、行政法规和国务院决定规定设立公司必须报经批准的或公司申请登记的经营范围中有法律、行政法规和国务院决定规定必须在登记前报经批准的项目，提交批准文件或者许可证件的复印件。</li>
        </ul>`,
      };
    } else if (id == 4) {
      obj = {
        title: '高新技术企业的认定条件是什么?',
        content: `
        <h3>受理条件详情：</h3>
        <ul>
            <li>企业申请认定时须注册成立一年以上。</li>
            <li>企业通过自主研发、受让、受赠、并购等方式，获得对其主要产品（服务）在技术上发挥核心支持作用的知识产权的所有权。            </li>
            <li>对企业主要产品（服务）发挥核心支持作用的技术属于《国家重点支持的高新技术领域》规定的范围。            </li>
            <li>企业从事研发和相关技术创新活动的科技人员占企业当年职工总数的比例不低于10%。            </li>
            <li>企业近三个会计年度（实际经营期不满三年的按实际经营时间计算，下同）的研究开发费用总额占同期销售收入总额的比例符合如下要求： 1、最近一年销售收入小于5,000万元（含）的企业，比例不低于5%； 2、最近一年销售收入在5,000万元至2亿元（含）的企业，比例不低于4%； 3、最近一年销售收入在2亿元以上的企业，比例不低于3%；其中，企业在中国境内发生的研究开发费用总额占全部研究开发费用总额的比例不低于60%。</li>
            <li>近一年高新技术产品（服务）收入占企业同期总收入的比例不低于60%。            </li>
            <li>企业创新能力评价应达到相应要求。            </li>
            <li>企业申请认定前一年内未发生重大安全、重大质量事故或严重环境违法行为。            </li>
        </ul>
        `,
      };
    }
    setInfo(obj);
  }, {});

  return (
    <>
      <div>
        <img
          style={{ width: '100%', height: '300px', objectFit: 'cover' }}
          src="/static/img/banner2.jpg"
          alt=""
        />
      </div>
      <div id="container-con" className="center">
        <div className="top_lj">
          <a href="/">首页</a> &gt; <a href="/service">企业服务</a> &gt;
          <strong> 大家都在问</strong>
        </div>
        <div className="fen-article-con">
          <ul className="t">
            <h2>{info && info.title}</h2>
          </ul>

          <div className="news_content">
            <div
              dangerouslySetInnerHTML={{ __html: info && info.content }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

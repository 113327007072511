import React from 'react';
import ReactDOM from 'react-dom'
import Router from './Router'
import zhCN from 'antd/es/locale/zh_CN'
import { ConfigProvider } from 'antd'
import ScrollToTop from './js/ScrollToTop'

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <Router>
      <ScrollToTop />
    </Router>
  </ConfigProvider>,
  document.getElementById('root')
);
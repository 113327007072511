import React, { useState } from 'react'
import './About.css'

export default function Col5() {
  const [active, setActive] = useState(-1)
  var overChange = (a) => {
    setActive(a)
    console.log(a,active)
  }

  return (
    <>
      <div className="container inner-padding-tb2 text-center">
        <div className="line off"></div>
        <div className={active==0?'item on':'item off'} onMouseOver={()=>overChange(0)} style={{marginTop:'-60px',height:'105px'}}>
          <div className="circle">
            <img className="lingxing" src="/static/img/green-lingxing.png" alt=""/>
            <img className="donut" src="/static/img/blue-donut.png" alt=""/>
          </div>
          <div className="title"> <span>2022 年</span></div>
          <div className="content">
            <div className="zh"> </div>
          </div>
        </div>
        <div  className={active==1?'item on':'item off'} onMouseOver={()=>overChange(1)} style={{height:'226px'}}>
          <div className="circle">
            <img className="lingxing" src="/static/img/green-lingxing.png" alt=""/>
            <img className="donut" src="/static/img/blue-donut.png" alt=""/>
          </div>
          <div className="title">
            <span>2021 年</span>
          </div>
          <div className="content">
            <div className="zh"></div>
          </div>
        </div>
        <div className={active==2?'item on':'item off'} onMouseOver={()=>overChange(2)} style={{marginTop:'-60px',height:'105px'}}>
          <div className="circle">
            <img className="lingxing" src="/static/img/green-lingxing.png" alt=""/>
            <img className="donut" src="/static/img/blue-donut.png" alt=""/>
          </div>
          <div className="title"> <span>2020 年</span></div>
          <div className="content">
            <div className="zh">入选“杭州数字经济旅游十景”</div>
          </div>
        </div>
        <div  className={active==3?'item on':'item off'} onMouseOver={()=>overChange(3)} style={{height:'226px'}}>
          <div className="circle">
            <img className="lingxing" src="/static/img/green-lingxing.png" alt=""/>
            <img className="donut" src="/static/img/blue-donut.png" alt=""/>
          </div>
          <div className="title">
            <span>2019 年</span>
          </div>
          <div className="content">
            <div className="zh">列入省级特色小镇第五批创建名单</div>
          </div>
        </div>
        <div  className={active==4?'item on':'item off'} onMouseOver={()=>overChange(4)} style={{height:'226px'}}>
          <div className="circle">
            <img className="lingxing" src="/static/img/green-lingxing.png" alt=""/>
            <img className="donut" src="/static/img/blue-donut.png" alt=""/>
          </div>
          <div className="title">
            <span>2018 年</span>
          </div>
          <div className="content">
            <div className="zh">列入省级特色小镇第三批培育名单</div>
          </div>
        </div>
        <div  className={active==5?'item on':'item off'} onMouseOver={()=>overChange(5)} style={{height:'226px'}}>
          <div className="circle">
            <img className="lingxing" src="/static/img/green-lingxing.png" alt=""/>
            <img className="donut" src="/static/img/blue-donut.png" alt=""/>
          </div>
          <div className="title">
            <span>2017 年</span>
          </div>
          <div className="content">
            <div className="zh">图灵小镇首次亮相</div>
          </div>
        </div>
      </div>
    </>
  )
}

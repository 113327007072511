import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { article } from '../../js/api';
import './News.css';

export default function NewsDetail() {
  const [info, setInfo] = useState({});
  const location = useLocation();

  useEffect(async () => {
    await article(location.state.content.id, { mark: 1 });
    setInfo(location.state && location.state.content);
  }, []);

  return (
    <>
      <div>
        <img
          style={{ width: '100%', height: '300px', objectFit: 'cover' }}
          src="/static/img/banner2.jpg"
          alt=""
        />
      </div>
      <div id="container-con" className="center">
        <div className="top_lj">
          <a href="/">首页</a> &gt; <a href="/news">资讯中心</a> &gt;
          <strong> 资讯详情</strong>
        </div>
        <div className="fen-article-con">
          <ul className="t">
            <h2>{info && info.title}</h2>
          </ul>
          <ul className="zy">
            <span>信息来源：{info && info.author}</span>|
            <span>发布时间：{info && info.publishTime}</span>|{' '}
            <span>浏览次数 340次</span>
          </ul>
          <div className="news_content">
            <div
              dangerouslySetInnerHTML={{ __html: info && info.content }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

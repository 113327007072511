import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import './About.css';
import './About.scss';
import Col1 from './Col1';
import Col2 from './Col2';
import Col3 from './Col3';
import Col4 from './Col4';
import Col5 from './Col5';
export default function About() {
  const [active, setActive] = useState(0);
  const [key, setKey] = useState(0);
  var tabClick = (tabIndex) => setActive(tabIndex);

  function Greeting(props) {
    const active = props.active;
    if (active === 0) {
      return <Col1 />;
    } else if (active === 1) {
      return <Col2 />;
    } else if (active === 2) {
      return <Col3 />;
    } else if (active === 3) {
      return <Col4 />;
    } else if (active === 4) {
      return <Col5 />;
    }
  }

  useEffect(() => {
    setKey(Math.random());
  }, [localStorage.getItem('locale')]);
  return (
    <>
      <div className="header_img">
        <img
          className="header_bg"
          style={{ width: '100%', height: '300px', objectFit: 'cover' }}
          src="/static/header/小镇概况.jpg"
          alt=""
        />
        <img src="/static/header/小镇概况.png" alt="" className="header_title"></img>
      </div>
      <div className="center mt20 mb20" key={key}>
        <ul className="drven-list">
          <li onClick={() => tabClick(0)}>
            <div className="drven-icon">
              {active === 0 ? (
                <img src="/static/about/1.1.png" alt="" />
              ) : (
                <img src="/static/about/1.png" alt="" />
              )}
            </div>{' '}
            <p>{intl.get('about.TownProfile')}</p>{' '}
            <div
              className={
                active === 0
                  ? 'drven-active-line mt10 active'
                  : 'drven-active-line mt10'
              }
            ></div>
          </li>
          <li onClick={() => tabClick(1)}>
            <div className="drven-icon">
              {active === 1 ? (
                <img src="/static/about/2.1.png" alt="" />
              ) : (
                <img src="/static/about/2.png" alt="" />
              )}
            </div>{' '}
            <p>{intl.get('about.KeyProjects')}</p>
            <div
              className={
                active === 1
                  ? 'drven-active-line mt10 active'
                  : 'drven-active-line mt10'
              }
            ></div>
          </li>
          <li onClick={() => tabClick(2)}>
            <div className="drven-icon">
              {active === 2 ? (
                <img src="/static/about/3.1.png" alt="" />
              ) : (
                <img src="/static/about/3.png" alt="" />
              )}
            </div>{' '}
            <p>{intl.get('about.KeyPlatform')}</p>{' '}
            <div
              className={
                active === 2
                  ? 'drven-active-line mt10 active'
                  : 'drven-active-line mt10'
              }
            ></div>
          </li>
          <li onClick={() => tabClick(3)}>
            <div className="drven-icon">
              {active === 3 ? (
                <img src="/static/about/4.1.png" alt="" />
              ) : (
                <img src="/static/about/4.png" alt="" />
              )}
            </div>{' '}
            <p>{intl.get('about.KeyEnterprises')}</p>{' '}
            <div
              className={
                active === 3
                  ? 'drven-active-line mt10 active'
                  : 'drven-active-line mt10'
              }
            ></div>
          </li>
          {/* <li onClick={() => tabClick(4)}>
            <div className="drven-icon">
              {active == 4 ? (
                <img src="/static/img/7.1.png" alt="" />
              ) : (
                <img src="/static/img/7.png" alt="" />
              )}
            </div>{' '}
            <p>未来规划</p> <p className="drven-icon-desc">小镇未来</p>{' '}
            <div
              className={
                active == 4
                  ? 'drven-active-line mt10 active'
                  : 'drven-active-line mt10'
              }
            ></div>
          </li> */}
        </ul>
      </div>
      <Greeting active={active} />
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Enter.css";
import "./Enter.scss";
import { apply } from "../../js/api";
import detail from "./detail.json";

import { Form, Input, Button, DatePicker, message } from "antd";
import intl from "react-intl-universal";
const { TextArea } = Input;

export default function Enter() {
  var navigate = useNavigate();
  let newsList = detail.newsList;
  var time = "";
  const [form] = Form.useForm();
  const [active, setActive] = useState(0);
  const [list, setList] = useState([]);
  const [tab, setTab] = useState(0);
  const location = useLocation();
  var tabClick = (tabIndex) => setActive(tabIndex);
  useEffect(() => {
    if (location.state && location.state.content) {
      setActive(location.state.content);
    }
  }, []);
  useEffect(() => {
    let arr = [];
    if (tab === 0) arr = newsList.filter((el) => el.type === 2);
    else if (tab === 1) arr = newsList.filter((el) => el.type === 3);
    else if (tab === 2) arr = newsList.filter((el) => el.type === 4);
    setList(arr);
  }, [tab]);
  var recommendedClick = (tabIndex) => {
    setTab(tabIndex);
  };
  const onFinish = (values) => {
    var dataform = Object.assign({}, values);
    dataform.startTime = dataform.endTime = time;
    delete dataform.time;
    apply(dataform).then((res) => {
      if (res.error_code) {
        message.error("提交失败");
      } else {
        message.success({
          content:
            "预约提交成功，小镇工作人员将在1-2个工作日与您取得联系（节假日除外）。如有任何疑问，可联系0571-82201959",
          duration: 5,
          style: {
            marginTop: "30vh",
          },
        });
        form.resetFields();
      }
    });
  };
  var tabList = ["周边美食", "周边住宿", "周边购物"];
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const sel = (v, i) => {
    v.preventDefault();
    console.log(i);
    navigate("/enterdetail", {
      state: {
        content: i,
      },
    });
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    time = dateString;
  };

  return (
    <>
      <div className="header_img">
        <img
          className="header_bg"
          style={{ width: "100%", height: "300px", objectFit: "cover" }}
          src="/static/header/景区导览.jpg"
          alt=""
        />
        <img
          src="/static/header/景区导览.png"
          alt=""
          className="header_title"
        ></img>
      </div>
      <div className="center mt20 mb20">
        <ul className="drven-list">
          <li onClick={() => tabClick(0)}>
            <div className="drven-icon">
              {active === 0 ? (
                <img src="/static/img/4.1.png" alt="" />
              ) : (
                <img src="/static/img/4.png" alt="" />
              )}
            </div>{" "}
            <p>{intl.get("enter.Appointment")}</p>{" "}
            <div
              className={
                active === 0
                  ? "drven-active-line mt10 active"
                  : "drven-active-line mt10"
              }
            ></div>
          </li>
          <li onClick={() => tabClick(1)}>
            <div className="drven-icon">
              {active === 1 ? (
                <img src="/static/img/5.1.png" alt="" />
              ) : (
                <img src="/static/img/5.png" alt="" />
              )}
            </div>{" "}
            <p>{intl.get("enter.OnlineTour")}</p>{" "}
            <div
              className={
                active === 1
                  ? "drven-active-line mt10 active"
                  : "drven-active-line mt10"
              }
            ></div>
          </li>
          <li onClick={() => tabClick(2)}>
            <div className="drven-icon">
              {active === 2 ? (
                <img src="/static/about/7.1.png" alt="" />
              ) : (
                <img src="/static/about/7.png" alt="" />
              )}
            </div>{" "}
            <p> {intl.get("enter.ToursRoute")}</p>{" "}
            <div
              className={
                active === 2
                  ? "drven-active-line mt10 active"
                  : "drven-active-line mt10"
              }
            ></div>
          </li>
          <li onClick={() => tabClick(3)}>
            <div className="drven-icon">
              {active === 3 ? (
                <img src="/static/img/6.1.png" alt="" />
              ) : (
                <img src="/static/img/6.png" alt="" />
              )}
            </div>{" "}
            <p>{intl.get("enter.ShoppingArround")}</p>{" "}
            <div
              className={
                active === 3
                  ? "drven-active-line mt10 active"
                  : "drven-active-line mt10"
              }
            ></div>
          </li>
        </ul>

        <div style={{ display: active === 0 ? "block" : "none" }}>
          <div className="center form">
            <div style={{ marginTop: "40px" }}>
              <div className="center-title text-center">
                <h3>展厅预约</h3>{" "}
                <div className="lineText">
                  <span>APPOINTMENT</span>
                </div>
              </div>{" "}
              <div className="call">
                预约咨询电话：0571-82640098、0571-82201950
              </div>
            </div>
            <Form
              form={form}
              name="basic"
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 20,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              {/* <Form.Item
                label="活动名称"
                name="activityName"
                rules={[
                  {
                    required: true,
                    message: '请输入活动名称',
                  },
                ]}
              >
                <Input placeholder="请输入活动名称" />
              </Form.Item> */}

              <Form.Item
                label="预约时间"
                name="time"
                rules={[
                  {
                    required: true,
                    message: "请选择预约时间",
                  },
                ]}
              >
                <DatePicker onChange={onChange} placeholder="请选择预约时间" />
              </Form.Item>

              <Form.Item
                label="联系人姓名"
                name="applier"
                rules={[
                  {
                    required: true,
                    message: "请输入联系人姓名",
                  },
                ]}
              >
                <Input placeholder="请输入联系人姓名" />
              </Form.Item>

              <Form.Item
                label="联系人电话"
                name="applierMobile"
                rules={[
                  {
                    required: true,
                    message: "请输入联系人电话",
                  },
                ]}
              >
                <Input placeholder="请输入联系人电话" />
              </Form.Item>

              <Form.Item
                label="联系人单位"
                name="applierUnit"
                rules={[
                  {
                    required: true,
                    message: "请输入联系人单位",
                  },
                ]}
              >
                <Input placeholder="请输入联系人单位" />
              </Form.Item>

              <Form.Item
                label="参观人数"
                name="number"
                rules={[
                  {
                    required: true,
                    message: "请输入活动人数",
                  },
                ]}
              >
                <Input placeholder="请输入活动人数" />
              </Form.Item>

              <Form.Item label="备注" name="mark">
                <TextArea
                  rows={4}
                  placeholder="请输入备注"
                  maxLength={300}
                  showCount
                />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>

        <div style={{ display: active == 1 ? "block" : "none" }}>
          <div id="peopleShow" style={{ marginTop: "40px" }}>
            <div className="center-title text-center">
              <h3>小镇游览</h3>{" "}
              <div className="lineText">
                <span>LOCAL DISPLAY</span>
              </div>
            </div>
          </div>
          <div className="center">
            <ul className="listenter flex">
              <li>
                <a
                  target="_blank"
                  href="https://qixiu-qiniu.ninewe.com/%E5%B0%8F%E9%95%87%E4%BA%A7%E4%B8%9AV1.9.mp4"
                  className="pcVideo"
                  rel="noreferrer"
                ></a>
                <img src="/static/img/play.png" className="play" />
                <div className="t">
                  <h4>小镇产业</h4>
                  <p>数字经济新时代</p>
                </div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://qixiu-qiniu.ninewe.com/%E5%B0%8F%E9%95%87%E7%9B%AE%E6%A0%87V1.6.mp4"
                  className="pcVideo"
                  rel="noreferrer"
                ></a>
                <img src="/static/img/play.png" className="play" />
                <div className="t">
                  <h4>小镇目标</h4>
                  <p>阶段性目标与成果</p>
                </div>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://qixiu-qiniu.ninewe.com/%E5%B0%8F%E9%95%87%E7%94%9F%E6%B4%BBV2.0.mp4"
                  className="pcVideo"
                  rel="noreferrer"
                ></a>
                <img src="/static/img/play.png" className="play" />
                <div className="t">
                  <h4>小镇生活</h4>
                  <p>美丽小镇美好生活</p>
                </div>
              </li>
            </ul>
            <div id="peopleShow" style={{ marginTop: "80px" }}>
              <div className="center-title text-center">
                <h3>景区导览</h3>{" "}
                <div className="lineText">
                  <span>SCENIC TOUR</span>
                </div>
              </div>
            </div>
            <div className="center flex align-center justify-center">
              <div>
                <h2 className="mb20">景区导览</h2>{" "}
                <p className="">
                  这是一个位于城市中心的小镇。专注数字经济，聚焦人工智能，推动产城融合。政策咨询实时看，活动信息全预览，名企故事深度谈。
                </p>
              </div>{" "}
              <div className="">
                <img
                  src="/static/img/wxcode.png"
                  style={{ width: "140px", height: "140px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="route text-center"
          style={{ display: active === 2 ? "block" : "none" }}
        >
          <h2>数智魅力 • 瞰图灵</h2>
          <p>线路一：图灵小镇游客服务中心</p>
          <p>线路二：图灵小镇游客服务中心—长三角（杭州）制造业数字化能力中心</p>
          <p>
            线路三：图灵小镇游客服务中心—浙江大学计算机创新技术研究院—长三角（杭州）制造业数字化能力中心
          </p>
          <p>
            线路四：图灵小镇游客服务中心—长三角（杭州）制造业数字化能力中心—中国（杭州）5G创新谷
          </p>
          <p>
            线路五：图灵小镇游客服务中心—浙江大学计算机创新技术研究院—长三角（杭州）制造业数字化能力中心—中国（杭州）5G创新谷
          </p>
          <video
            src="https://qiniu-cjyz.ninewe.com/%E6%95%B0%E6%99%BA%E9%AD%85%E5%8A%9B%20%E6%B1%87%E5%9B%BE%E7%81%B5.mp4"
            controls
          ></video>
          <h2>人文风采 • 游图灵</h2>
          <p>线路一：图灵小镇游客服务中心—钱江世纪公园</p>
          <p>线路二：图灵小镇游客服务中心—钱江世纪公园—ITC归谷国际中心</p>
          {/* <img src="/static/img/r2.png" alt="" /> */}
          <video
            src="https://qiniu-cjyz.ninewe.com/%E9%A3%8E%E9%87%87%E4%BA%BA%E6%96%87%20%E6%B8%B8%E5%9B%BE%E7%81%B5.mp4"
            controls
          ></video>
        </div>
        <div
          className="recommended "
          style={{ display: active === 3 ? "block" : "none" }}
        >
          <div className="tabs">
            {tabList.map((v, i) => {
              return (
                <a
                  key={i}
                  className={tab == i ? "active" : ""}
                  onClick={() => recommendedClick(i, v)}
                >
                  <div href="#!">{v}</div>
                </a>
              );
            })}
          </div>
          <div className="list">
            {list.map((v, i) => {
              return (
                <div className="item" key={i} onClick={(e) => sel(e, v)}>
                  <div dangerouslySetInnerHTML={{ __html: v.img }}></div>
                  <div className="b">
                    <div className="l">
                      <div>2022</div>
                      02-23
                    </div>
                    <div className="r">
                      <div className="title">{v.title}</div>
                      <div className="detail">{v.address}</div>
                    </div>
                  </div>
                </div>
                // <a
                //   key={i}
                //   className={tab == i ? 'active' : ''}
                //   onClick={() => recommendedClick(i, v)}
                // >
                //   <div href="#!">{v}</div>
                // </a>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

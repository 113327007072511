import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper';

export default function Col4() {
  const [obj, setObj] = useState({
    name: '浙江商汤科技开发有限公司',
    detail:
      '商汤科技是一家行业领先的人工智能软件公司，以“坚持原创，让AI引领人类进步”为使命。商汤科技拥有深厚的学术积累，并长期投入于原创技术研究，不断增强行业领先的全栈式人工智能能力，涵盖感知智能、决策智能、智能内容生成和智能内容增强等关键技术领域，同时包含AI芯片、AI传感器及AI算力基础设施在内的关键能力。此外，商汤还前瞻性打造新型人工智能基础设施——SenseCore商汤AI大装置，打通算力、算法和平台，大幅降低人工智能生产要素价格，实现高效率、低成本、规模化的AI创新和落地，进而打通商业价值闭环，解决长尾应用问题，推动人工智能进入工业化发展阶段。“以人工智能实现物理世界和数字世界的连接，促进社会生产力可持续发展，并为人们带来更好的虚实结合生活体验”是商汤科技的公司愿景，其业务涵盖智慧商业、智慧城市、智慧生活、智能汽车四大板块，相关产品与解决方案深受客户与合作伙伴好评。2021年12月30日，商汤（股票代码：0020.HK）在香港交易所主板挂牌上市。在香港、上海、北京、深圳、成都、杭州、南平、青岛、三亚、西安、台北、澳门、京都、东京、新加坡、利雅得、阿布扎比、迪拜、吉隆坡、首尔设立办公室。另外，商汤科技在泰国、印度尼西亚、菲律宾等国家均有业务。',
  });
  const [show, setShow] = useState(false);
  let list = [
    {
      name: '浙江商汤科技开发有限公司',
      detail:
        '商汤科技是一家行业领先的人工智能软件公司，以“坚持原创，让AI引领人类进步”为使命。商汤科技拥有深厚的学术积累，并长期投入于原创技术研究，不断增强行业领先的全栈式人工智能能力，涵盖感知智能、决策智能、智能内容生成和智能内容增强等关键技术领域，同时包含AI芯片、AI传感器及AI算力基础设施在内的关键能力。此外，商汤还前瞻性打造新型人工智能基础设施——SenseCore商汤AI大装置，打通算力、算法和平台，大幅降低人工智能生产要素价格，实现高效率、低成本、规模化的AI创新和落地，进而打通商业价值闭环，解决长尾应用问题，推动人工智能进入工业化发展阶段。“以人工智能实现物理世界和数字世界的连接，促进社会生产力可持续发展，并为人们带来更好的虚实结合生活体验”是商汤科技的公司愿景，其业务涵盖智慧商业、智慧城市、智慧生活、智能汽车四大板块，相关产品与解决方案深受客户与合作伙伴好评。2021年12月30日，商汤（股票代码：0020.HK）在香港交易所主板挂牌上市。在香港、上海、北京、深圳、成都、杭州、南平、青岛、三亚、西安、台北、澳门、京都、东京、新加坡、利雅得、阿布扎比、迪拜、吉隆坡、首尔设立办公室。另外，商汤科技在泰国、印度尼西亚、菲律宾等国家均有业务。',
    },
    {
      name: '杭州易现先进科技有限公司',
      detail:
        '易现EZXR是一家专注于人工智能、增强现实技术研发公司，公司前身是网易人工智能事业部，该部门自2011年始建于网易杭州研究院，承担集团全栈人工智能技术的应用和探索，并通过服务网易系主要互联网产品触达近10亿用户，峰值期间覆盖8000万人/天。易现EZXR于2019年4月完成由国内人工智能领域主要早期投资机构之一的元禾原点领投的逾1.3亿元人民币首轮融资，新湖智脑、头头是道等跟投，网易作为原始大股东持股，并于2020年6月的杭州万物生长大会获评“准独角兽企业”称号。',
    },
    {
      name: '派恩杰半导体（杭州）有限公司',
      detail:
        '派恩杰半导体（杭州）有限公司成立于2018年9月，是第三代半导体功率器件设计销售企业（Fabless模式），持续专注于碳化硅和氮化镓功率器件设计研发与产品销售。派恩杰产品有SiC SBD，SiC MOS以及GaN HEMT等，广泛应用于服务器及数据中心电源、新能源汽车、智能电网、5G物联网、工业电机、逆变器等场景和领域。成立仅6个月即完成第一款可兼容驱动650V GaN功率器件；同年发布Gen3技术的1200V SiC MOS产品，技术指标国内领先；2020年公司推出650V、1700V SiC MOS产品，完成三大产品系列布局；2021年公司推出650V、1200V SiC SBD全阵容产品，丰富了650V、1200V、1700V SiC MOS产品线，将发布100余款不同型号功率器件。',
    },
    {
      name:'杭州行至云起科技有限公司',
      detail:'杭州行至云起科技有限公司成立于2013年，核心团队来自阿里巴巴、华为、UT斯达康、施耐德等世界500强企业，拥有电信级通信技术背景，科研人员占公司总部人数50%以上，是最早进入智能家居领域的品牌之一，也是业内少有进行全球化布局的国内智能家居品牌。',
      detail1:'LifeSmart云起是全球化的AIoT解决方案提供商，也是行业内少数自研媲美有线网络稳定性的无线通信协议CoSS™协议的企业，完整支持市场上主流通讯协议，包括Wi-Fi、CoSS™、Zigbee、Z-Wave、Bluetooth、Matter六大通讯协议。LifeSmart云起提供自主研发的智能硬件、丰富的应用软件和开放的云服务，整合行业领先人工智能技术，为用户提供“1+2+6”的全屋智能解决方案，在智能照明、智能遮阳、暖通环境、安全防护、智能控制、智能影音等领域打造全场景智能化体验，为每一位用户的生活、工作提升效率，创造价值。'
    },
    {
      name:'蓝卓工业互联网（杭州）有限公司',
      detail:'蓝卓工业互联网是由中国工业自动化、数字化、智能化领域的顶尖科学家褚健教授创立，是国内领先的工业互联网、工业大数据和工业人工智能平台和解决方案提供商。企业自主研发的SupOS工业操作系统是我国首个具备自主知识产权的工业操作系统。蓝卓致力探索以“平台+工业智能应用”模式，积极利用工业互联网、大数据、人工智能等新技术，从解决企业实际问题出发，持续推动企业数字化转型升级，赋能智慧城市数字化建设。',
    },
    {
      name:'浙江水木物联技术有限公司',
      detail:'浙江水木物联技术有限公司由清华大学创业团队孵化，是一家专业提供物联网解决方案的国家高新技术企业，是国内领先的掌握覆盖多频段、多协议无线自组织网络技术的公司。水木物联以低功耗无线传输技术为支撑，以物联网智能互联感知平台为依托，为企业提供智能化技术改造、数据应用、工业应用软件开发和工业互联网平台等数字化服务。目前，公司核心产品及解决方案覆盖工业、电力、园区、城市、教育、环保、能源、交通等12个行业和领域，100多家用户单位，累计申请发明专利100多项，并以每年30%的专利申请速度递增，引领智能物联网创新发展。',
    },
    {
      name:'新唐信通（浙江）科技有限公司',
      detail:'新唐信通(浙江)科技有限公司(简称“新唐信通”)是一家混改高科技企业，致力于用信息通信技术赋能行业发展，为社会和城市治理提供解决方案。依托通信央企和网络与交换技术国家重点实验室资源，目前聚焦智慧交通行业，提供智能汽车时代安全、自主、可控、可靠.可持续运营的交通智能网运营服务。',
      detail1:'作为交通智能网运营服务企业，新唐信通通过5G、V2X、群体智能、数字李生等赋能全息数字路网和自动驾驶，打造运营平台、管道、安全、终端、应用五位一体的运营服务模式，支撑各运营主体进行持续的商业化运营，助力地方政府发展智慧交通产业。同时通过CA认证实现信息安全监管，面向园区、开放城市道路、高速等多种场景提供运营服务支撑，构建人车路环境和谐智能新生态。'
    },
    {
      name:'杭州一隅千象科技有限公司',
      detail:`一隅千象2016年成立于美国加州洛杉矶，中国杭州和美国硅谷双总部，是一家拥有独立知识产权的产品平台公司，致力于无任何穿戴设备下实现裸眼混合现实交互。公司自主研发的裸眼3D互动空间设备系统n'Space，通过AI驱动三维一体（应用程序+算法+硬设），在给进入者带来震撼视听体验的同时，其特有的场随人动机制，姿态识别、位置触发、语音交互、外设控制等各类交互选项，能广泛满足客户全沉浸式交互应用需求，赋能数字仿真和孪生应用。`,
      detail1:`目前，一隅千象已完成三轮次千万级股权融资，为工业制造、文化娱乐、教育培训、商业营销、会展会务、公共服务等细分行业，定制了基于n'Space P371产品的裸眼360°沉浸式数字化场景或行业解决方案，服务客户包括多个世界五百强、央企国企、双一流高校、行业顶流，如欧莱雅集团、开云集团、海尔集团、交通银行、蚂蚁金服、清华大学、同济大学、虚拟歌姬“洛天依”、科大讯飞等。 `
    },
    {
      name:'杭州舜云互联技术有限公司',
      detail:'浙江舜云互联技术有限公司(简称“舜云互联”)，继承全球著名电机与驱控系统制造商近四十年的工业制造基因，沉淀十多年来的数字化转型和智能制造成功实践，成功打造舜智云(iMotorLinx)工业互联网平台，面向全球电驱产业上中下游全产业，提供数字化产品及业务运营整体解决方案服务。',
      detail1:'舜云互联以“赋能产业，驱动未来”为使命，构建了“ 1+1+N” 产品服务体系，打造产业数字化运营底座，建设电机产业大脑，面向全球电机及驱控系统产业链提供 N 个场景的“线上+线下”相结合的业务运营服务，包括iMotor设备全生命周期服务、iMom智能制造/未来工厂、iSCC供应链集采服务、iStore产业生态赋能等场景的数字化解决方案与运营服务，带动万亿级产业集群的发展，成为产业互联网领跑者。'
    },
    {
      name:'拟仁智能科技（杭州）有限公司',
      detail:'拟仁智能是一家将人工智能与计算机图形学技术相结合的创新型科技公司，拥有快速构建智能虚拟人和元宇宙应用的各项核心技术，致力于打造高仿真、人格化、个性化的智能虚拟人产品和服务，为各类商业应用场景赋能。拟仁智能团队成员均为在计算机图形学和人工智能领域深耕多年的科学家和工程师，其中创始人刘钢博士具有多年图形图像和人工智能领域经验，熟知业界系统架构及落地实施方法论。',
      detail1:'拟仁智能的核心技术“智能虚拟人SaaS云服务平台”，是以智能虚拟人服务为核心的新一代人工智能云服务平台，用户无需具备人工智能、计算机图形学等专业知识，只需在应用端集成平台所提供的适当的SDK版本，就可以在应用中轻松拥有栩栩如生的、具备AI智能的数字人物角色。成立至今，拟仁智能已收获多个奖项，其中在艾媒咨询举办的2022年中国虚拟人创新势力评选活动中获得“最佳虚拟人软件服务商”称号，拥有3项专利、18项软件著作权和8个商标，为智能汽车、金融电信、旅游文创、广告营销等多个领域的客户提供商业化的智能虚拟人服务。'
    },
    {
      name:'杭州恒领科技有限公司',
      detail:'杭州恒领科技有限公司是集研发、生产、销售为一体的，专业从事汽车智能网联解决方案及相关产品的国家高新技术企业。恒领科技成功研发了车联网智能模块 (TCU/T-BOX)、车辆安全辅助监控终端、车载以太网信息终端、汽车行驶记录仪、ADAS、DMS、国六检测终端、V2X、车联网服务平台，以及车主APP等多个解决方案和系列产品，并参与国家标准GB/T19056-2012《汽车行驶记录仪》、交通部标准《道路运输车辆卫星定位系统北斗兼容车载终端通讯协议及数据格式》等标准的制定，致力加强汽车产业上下游紧密融合，为汽车智能网行业提供技术驱动。',
    },
    {
      name:'浙江碳银数智绿能科技有限公司',
      detail:'浙江碳银数智绿能科技有限公司（简称“碳银”）成立于2015年，以打造“城乡绿色产业数字化服务平台”为公司核心定位。公司致力于为从事家庭清洁能源服务和分布式光伏能源投资的小微企业提供“供、管、融、维”等全价值链服务支持，旨在帮助业内小微企业创业与发展。另外，公司积极推进绿色旅居数字智造产业服务平台的建设，打造绿色智慧旅居数字智造工厂、产业服务支持和数字化解决方案。',
    },
    // {
    //   img: '杭州今元标矩科技有限公司.webp',
    //   name: '杭州今元标矩科技有限公司',
    //   detail:
    //     '金柚网是一家人力资源数字化服务商，公司以“连接企业，连接人”为核心价值，构建B+C全景式全链路数字化人力资源生态体系，已为全国7万多家企业提供智能化、模块化、多元化、定制化人力资源解决方案，激发创新力量，提升管理效能，促进商业变革。 公司组建起以人力资源、互联网技术、法务、财务为核心的专家团队，保证了服务专业性、及时性与安全性，在劳务派遣、岗位外包、灵活用工、商业保险等方面为70000多家客户提供富有竞争力的产品、服务与解决方案，广泛服务于互联网、餐饮、金融、快消、医药、房地产、教育、外贸等多个领域。持续为用户创造价值。金柚网始终坚持以客户为中心，搭建起遍及全国的自营服务网络，拥有155家自营分公司，实现“一地签约，全国服务”，提供高效便捷的服务体系。公司采用了全流程在线的互联网系统，实现全程在线操作，大幅降低企业成本，与全国伙伴合作共赢。 　　 促进行业生态建设。基于“线上+线下”一体化优势，公司一方面产业横向延伸，完善人资服务产业链，一方面垂直深耕，打造应用于不同行业的个性化解决方案，构建模块化、系统化的生态体系，服务于多元化场景，推动产业发展。 推动人资服务产业发展。公司深刻洞察“物联化、互联化、智能化”的产业发展大势，未来，将以“AI+人力资源”为发展动能，持续升级人力资源创新平台，推动人力资源行业向数字化、智能化、便捷化方向迈进，为打造网络强国、数字中国、智慧社会作出贡献。',
    // },
    // {
    //   name: '杭州宇链科技有限公司',
    //   detail:
    //     '宇链科技是浙江清华长三角研究院杭州分院重点投资培育企业，拥有国内最适合监管的底层区块链技术，是全国第一批获得国家网信办备案的区块链企业，全球唯一一家拥有“芯片+链+云”三位一体的可信数据基础设施技术服务商。宇链拥有领先的产品技术，将复杂的区块链底层简易化、模块化到BaaS层，实现区块链的高可用性和高易用性，降低使用门槛。公司自主研发全球首个区块链安全芯片，致力于核心技术创新，拥有区别于业内的独特商业模式。',
    // },
    // {
    //   name: '杭州炽橙数字科技有限公司',
    //   detail:
    //     '炽橙数字科技专注于虚拟现实（VR、AR、MR）技术在各行业智能化应用的集成服务，聚焦职业技能教育培训（社会职业院校/军队院校及训练中心）的系统解决方案，是中国虚拟现实与可视化产业技术创新战略联盟(CVRVT)理事和专家委员单位。2017年成为浙江省虚拟现实产业领军企业，2019年开创了国内5G+职业技能数字化融合项目实践。',
    // },
   
    // {
    //   name: '浙江天轮供应链管理有限公司',
    //   detail:
    //     '天轮供应链是一家专注于轮胎行业垂直运营，致力于打造协同采购、协同制造、技术研发、渠道分销、绿色回收、数字化平台为一体的“5+1”模式的国内首家轮胎生态供应链企业。公司成立以来，以协同化、定制化、去中间化、数字化、循环化的“五化”运营模式全面打造轮胎行业智慧供应链体系，形成了一套高效、精准、共享、绿色可循环发展的全新商业模式。2018年9月，被国家八部委列入“100家全国供应链创新与应用试点企业”名单。2019年3月，入选“2019杭州准独角兽企业”。',
    // },
    // {
    //   name: '浙江港流高分子科技股份有限公司',
    //   detail:
    //     '港流科技成立于2014年，是全球首家专注于新型高分子工业地坪材料研发、生产、销售、安装和服务为一体的专业性高科技企业，创新提出了工业地坪4.0概念，为客户在工业4.0转型升级的过程中提供基础保障配套服务，致力于引领工业地坪进入绿色环保铺装时代，更快、更好地推广工业地坪铺装化理念。',
    // },
    // {
    //   name: '杭州哈智机器人有限公司',
    //   detail:
    //     '哈智机器人是一家智能机器人综合解决方案提供商，在语音交互、图像识别、IOT物联网、智能运动、机器人底盘及平台打造、系统集成等底层技术维度实现诸多创新，能够精准解读电子政务、公共服务、商用办公和家庭生活中纷繁的场景需求，并为之提供针对性机器人解决方案。',
    // },
    // {
    //   name: '杭州爱教乐学科技有限公司',
    //   detail:
    //     '爱教乐学以中小学教育（K12）为切入点，利用互联网、人工智能、智能硬件的先进技术为用户提供优质的教学辅导服务产品，以让每个孩子都有机会接受优质教育为使命，在智能教学领域积极进取创新。旗下主要产品百分课堂是一个线上辅导教育机构，采用智能硬件和AI人工智能相结合，提升学习效率和体验，课堂内容研发团队、核心讲师团队都来自一线公立学校，所有老师均是24小时贴身服务，及时解答学习问题。',
    // },
    // {
    //   name: '布鲁可人工智能（杭州）有限公司',
    //   detail:
    //     '布鲁可以人工智能启蒙教育为愿景，研究和开发专为儿童设计的智能硬件、教学软件和幼教资源，形成具有权威性和科学性的布鲁可AiS一体化教育解决方案，助力园所打造科技特色，激发人工智能时代下的儿童创造力。',
    // },
    // {
    //   name: '浙江英树生物科技有限公司',
    //   detail:
    //     '英树秉承健康护肤的理念，严选来自世界各地优良产地的安全原料。运用新的科学技术萃取，完整保留活性护肤成分，用心关注肌肤问题，发现植物美肌的秘密。 一“净”、二“修”、三“养”的英树阶段式食疗护肤理念，解决问题肌肤不同阶段性的困扰。英树对每一款原料都经过严格测试，以全球严谨、权威的SGS检测为标准，确保品质，供给肌肤更完美的呵护。',
    // },

    // {
    //   img: '',
    //   name: '杭州企智互联科技有限公司',
    //   detail:
    //     '企智互联，专注于餐饮管理软件、食堂管理软件。公司成立于2015年2月，是一家国内最早专注“团餐市场”软件研发与运营的互联网公司。公司获得杭州市雏鹰计划企业证书、国家高新技术企业认定，目前拥有20余项智慧食堂软件著作权，更是唯一拥有“智慧食堂”商标的企业。目前公司全职人员140余人。其中：研发人员30人，技术实施人员80余人。公司聚集了一批高科技研发和技术人才，在全体的共同刻苦钻研、开拓创新、务实敬业之下，企业取得了长足稳健的发展。研发团队核心成员来自于阿里巴巴、网易、海康研究院、魅族等知名科技型企业，专业素质过硬，技术研发力量成熟，项目研发专注于智慧食堂管理系统，目前已经取得20余项软件著作权，并在技术上不断创新。公司总部位于杭州钱江世纪城。在广东、北京、四川设立了三家合资控股公司。陕西、深圳设立了全资分公司。筹建云南分公司及湖南分公司。在重庆、新疆、内蒙、山东、江苏、安徽等地总计设置了18个办事处。全国目前总计发展有20余家授权落地服务商。',
    // },
    // {
    //   img: '',
    //   name: '杭州行至云起科技有限公司',
    //   detail:
    //     'LifeSmart云起，是一家全球智能家居品牌，由杭州行至云起科技有限公司研制发布。产品致力于让软、硬件的结合服务于智能、环保的生活体验，目前上市单品有智慧中心、智能开关、智能窗帘、环境感应器、动态感应器和入墙开关等全屋智能系列产品。云起将人工智能与人文关怀相结合，通过将物理世界数字化，实现更人性化的智能家庭控制。 感应器感知环境的变化，依照大数据，自动设定家居环境，让整个家变成一个会思考的生态系统。云起提供一整套从全屋智能到智慧社区，从用户使用到物业管理，从智能硬件到后端平台的智慧地产方案，帮助地产和物业一站式地解决全链路智能化的问题。LifeSmart云起的核心团队来自阿里巴巴、华为、UT斯达康、施耐德等世界500强企业，拥有电信级通信技术背景，科研人员占公司总人数50% 以上。LifeSmart云起实现了科技和美学的融合，在成立之时我们就拥有一个多元化的全栈型设计团队。来自全球各大顶尖美术学院的设计师们合力设定了属于LifeSmart云起的审美标准，保证了品牌在全领域的精益求精。',
    // },
    // {
    //   img: '',
    //   name: '杭州智芯科微电子科技有限公司',
    //   detail:
    //     '杭州智芯科微电子科技有限公司颠覆性地将AI、模拟计算芯片技术相结合，创造了一种超低功耗、高性能的深度神经网络全新处理器，为语音唤醒/识别、图像分类/识别等边缘计算功能落户移动终端提供了无限可能性。首款离线语音AI芯片将于2020年中量产销售。目标应用领域包括边缘计算、AIoT及智能穿戴设备等。创始团队成员为来自国际知名半导体公司的资深芯片和系统专家，平均拥有20多年的行业经验。',
    // },
  ];
  let list1 = [];
  const sel = (v) => setObj(v);

  return (
    <>
      <div className="center  platform">
        {/* <marquee onMouseOver="this.stop()">
          <div className="marqueeList">
            {list.map((v, index) => {
              return (
                <div
                  className="item"
                  key={index}
                  onClick={() => {
                    sel(v);
                  }}
                >
                  <img src={`/static/企业/${v.name}.png`} alt=""></img>
                  <div className="name">{v.name}</div>
                </div>
              );
            })}
          </div>
        </marquee> */}
        <Swiper
          className="swiper-no-swiping"
          modules={[Autoplay]}
          slidesPerView={5}
          spaceBetween={20}
          loop={true}
          speed={2000}
          freeMode={true}
          autoplay={{
            delay: 1,
            disableOnInteraction: false,
          }}
        >
          {list.map((v, i) => {
            return (
              <SwiperSlide onClick={() => setObj(v)}>
                <img src={`/static/企业/${v.name}.png`} alt=""></img>
                <div className="name">{v.name}</div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <CSSTransition in={show} timeout={500} classNames="my-node">
          <div className={obj.name ? 'article active' : 'article hide'}>
            <div className="title">{obj.name}</div>
            <div className="desc">{obj.detail}</div>
            <div className="desc">{obj.detail1}</div>
          </div>
        </CSSTransition>
      </div>
    </>
  );
}

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function ServiceWeekly() {
  const location = useLocation();
  //   const [url, setUrl] = useState(null);
  let search = location.search.split('?')[1];
  //   if (search) setUrl(search);
  //   console.log(url);

  return (
    <>
      <div className="header_img">
        <img
          className="header_bg"
          style={{ width: '100%', height: '300px', objectFit: 'cover' }}
          src="/static/header/企业服务.jpg"
          alt=""
        />
        <img
          src="/static/header/企业服务.png"
          alt=""
          className="header_title"
        ></img>
      </div>
      <div className="center weekly">
        <img src={`https://www.turingtown.com/${search}.png`} alt="" />
      </div>
    </>
  );
}

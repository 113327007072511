import React from 'react';
//引入需要用到的页面组件
import App from './App';
import Home from './components/Home/Home';
import News from './components/News/News';
import Newsdetail from './components/News/Detail';
import Service from './components/Service/Service';
import Servicedetail from './components/Service/Detail';
import Servicecenter from './components/Service/Center';
import Serviceproblem from './components/Service/problem';
import ServiceWeekly from './components/Service/weekly';
import About from './components/About/About';
import Enter from './components/Enter/Enter';
import EnterDetail from './components/Enter/Detail';
import Activity from './components/Activity/Activity';
import ActivityDetail from './components/Activity/Detail';
import Show from './components/Show/Show';
//引入一些模块
import {
  BrowserRouter as Router,
  Routes,
  Route /*useRoutes*/,
} from 'react-router-dom';

/* const App = () => {
  return useRoutes([
    { path: '/', element: <Home></Home>},
    { path: '/news', element: <News></News>},
    { path: '/service', element: <Service></Service>},
    { path: '/about', element: <About></About>},
  ]);
}

const AppWrapper = () => {
  return (
    <Router>
      <App/>
    </Router>
  )
}

export default AppWrapper; */

const BaseRouter = () => (
  <Router>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="/" element={<Home />}></Route>
        <Route path="/news" element={<News />}></Route>
        <Route path="/newsdetail" element={<Newsdetail />}></Route>
        <Route path="/service" element={<Service />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/enter" element={<Enter />}></Route>
        <Route path="/enterdetail" element={<EnterDetail />}></Route>
        <Route path="/activity" element={<Activity />}></Route>
        <Route path="/activitydetail" element={<ActivityDetail />}></Route>
        <Route path="/servicedetail" element={<Servicedetail />}></Route>
        <Route path="/servicecenter" element={<Servicecenter />}></Route>
        <Route path="/serviceproblem" element={<Serviceproblem />}></Route>
        <Route path="/serviceweekly" element={<ServiceWeekly />}></Route>
      </Route>
      <Route path="/Show" element={<Show />}></Route>
    </Routes>
  </Router>
);

export default BaseRouter;

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './News.scss';
import intl from 'react-intl-universal';
import { getCateList } from '../../js/api';
import { Pagination } from 'antd';

export default function News() {
  const location = useLocation();
  var navigate = useNavigate();
  const todetail = (e, v) => {
    e.preventDefault();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    navigate('/newsdetail', {
      state: {
        content: v,
      },
    });
  };
  var list = [
    '小镇动态',
    '科技资讯',
    '政策资讯',
    '亚运资讯',
    '企业动态',
    '缤纷活动',
    '通知公告',
  ];
  const [newsList, setNewsList] = useState([]);
  const [Information, setInformation] = useState([]); //推荐资讯
  const [Dynamic, setDynamic] = useState([]); //推荐资讯
  const [active, setActive] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pagesize, setPagesize] = useState(10);
  const [content, setContent] = useState('');

  var tabClick = (index, category) => {
    setNewsList([]);
    setActive(index);
    getCateList({
      page: page,
      size: pagesize,
      category,
    }).then((res) => {
      if (!res.error_code) {
        if (!res.data.length) {
          setContent(<p style={{ textAlign: 'center' }}>暂无数据</p>);
        } else {
          setContent('');
        }
        setNewsList(res.data);
        setTotal(res.total);
      }
    });
  };

  var onShowSizeChange = (current, size) => {
    setPage(0);
    setPagesize(size);
    tabClick(active, list[active]);
  };
  var onChange = (page, pageSize) => {
    setPage(page - 1);
  };

  useEffect(() => {
    console.log(111);
    let search = location.search.split('?')[1];
    if (search) {
      let a = decodeURIComponent(search);
      console.log(a);
      let index = list.findIndex((el) => {
        return el === a;
      });
      setActive(index);
      setTimeout(() => {
        tabClick(index, a);
      }, 100);
    } else {
      tabClick(0, '小镇动态');
      if (location.state && location.state.cate === '通知公告') {
        setNewsList([]);
        tabClick(6, '通知公告');
      }
    }
  }, []);

  useEffect(() => {
    tabClick(active, list[active]);
  }, [page, pagesize]);

  useEffect(() => {
    getCateList({
      page: 0,
      category: '科技资讯',
    }).then((res) => {
      if (!res.error_code) {
        setInformation(res.data);
      }
    });
  }, [Information.length]);

  useEffect(() => {
    getCateList({
      page: 0,
      size: 6,
      category: '小镇动态',
    }).then((res) => {
      if (!res.error_code) {
        setDynamic(res.data);
      }
    });
  }, [Dynamic.length]);
  return (
    <>
      <div className="News">
        <div className="header_img">
          <img
            className="header_bg"
            style={{ width: '100%', height: '300px', objectFit: 'cover' }}
            src="/static/header/资讯中心.jpg"
            alt=""
          />
          <img
            src="/static/header/资讯中心.png"
            alt=""
            className="header_title"
          ></img>
        </div>
        <div className="center">
          <div className="tabs">
            <a
              className={active == 0 ? 'active' : ''}
              onClick={() => tabClick(0, '小镇动态')}
            >
              <div href="#!">{intl.get('news.NewsCenter')}</div>
            </a>
            <a
              className={active == 6 ? 'active' : ''}
              onClick={() => tabClick(6, '通知公告')}
            >
              <div href="#!">{intl.get('news.Announcements')}</div>
            </a>
            <a
              className={active == 2 ? 'active' : ''}
              onClick={() => tabClick(2, '政策资讯')}
            >
              <div href="#!">{intl.get('news.PolicyInfo')}</div>
            </a>
            <a
              className={active == 1 ? 'active' : ''}
              onClick={() => tabClick(1, '科技资讯')}
            >
              <div href="#!">{intl.get('news.TechInfo')}</div>
            </a>
            <a
              className={active == 4 ? 'active' : ''}
              onClick={() => tabClick(4, '企业动态')}
            >
              <div href="#!">{intl.get('news.Enterprise')}</div>
            </a>
            <a
              className={active == 5 ? 'active' : ''}
              onClick={() => tabClick(5, '缤纷活动')}
            >
              <div href="#!">{intl.get('news.Activities')}</div>
            </a>
            <a
              className={active == 3 ? 'active' : ''}
              onClick={() => tabClick(3, '亚运资讯')}
            >
              <div href="#!">{intl.get('news.AsianGamesInfo')}</div>
            </a>

            {/* {list.map((v, i) => {
              return (
                <a
                  key={i}
                  className={active == i ? 'active' : ''}
                  onClick={() => tabClick(i, v)}
                >
                  <div href="#!">{v}</div>
                </a>
              );
            })} */}
          </div>
          <div className="content News">
            <div className="left">
              <div className="list">
                {newsList.map((v, i) => {
                  return (
                    <div
                      key={i}
                      onClick={(e) => todetail(e, v)}
                      className="item"
                    >
                      <img src={v.cover} alt="" className="l" />
                      <div className="r">
                        <div className="title">
                          <a>{v.title}</a>
                        </div>
                        <div className="description">{v.description}</div>
                        <div className="b">
                          <div className="b_l">
                            <div className="author">发布人：{v.author}</div>
                            <div className="readCount">
                              <i></i>
                              {v.readCount +
                                Math.floor(Math.random() * 10000) || 0}
                            </div>
                          </div>
                          <div className="publishTime">{v.publishTime}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {content}
              <div className="text-right">
                <Pagination
                  style={{
                    display: total != 0 ? 'block' : 'none',
                    marginTop: '30px',
                  }}
                  total={total}
                  current={page + 1}
                  showSizeChanger
                  showQuickJumper
                  onChange={onChange}
                  onShowSizeChange={onShowSizeChange}
                  showTotal={(total) => `共 ${total} 条`}
                />
              </div>
            </div>
            <div className="right">
              <div className="information">
                <div className="sub_title">{intl.get('news.Recommend')}</div>
                {Information.map((v, i) => {
                  return (
                    <div
                      key={i}
                      onClick={(e) => todetail(e, v)}
                      className="item"
                    >
                      <img src={v.cover} alt="" className="l" />
                      <div className="r">{v.title}</div>
                    </div>
                  );
                })}
              </div>
              <div className="list">
                <div className="sub_title">{intl.get('news.ReadingList')}</div>
                {Dynamic.map((v, i) => {
                  return (
                    <div
                      key={i}
                      onClick={(e) => todetail(e, v)}
                      className="item"
                    >
                      <div
                        className="l"
                        style={{
                          background:
                            i === 0
                              ? 'rgb(197, 41, 40)'
                              : i === 1
                              ? 'rgb(219, 109, 31)'
                              : i === 2
                              ? 'rgb(241, 202, 74)'
                              : i === 3
                              ? 'rgb(94, 106, 210)'
                              : '',
                        }}
                      >
                        {i + 1}
                      </div>
                      <div className="r">
                        <a>{v.title}</a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useNavigate, useLocation } from 'react-router-dom';
import './Nav.css';
import { message } from 'antd';
export default function Nav() {
  var pathname = useLocation().pathname;
  var [active, setActive] = useState(pathname);
  var navigate = useNavigate();
  var navTo = (url) => {
    setActive(url);
    navigate(url);
  };
  let change = () => {
    console.log(localStorage.getItem('locale'));
    if (localStorage.getItem('locale') === 'en') {
      localStorage.setItem('locale', 'zh');
    } else {
      localStorage.setItem('locale', 'en');
    }
    window.location.reload();
  };

  const location = useLocation();

  const info = () => {
    message.info('暂未上线，准备中！');
  };

  useEffect(() => {
    setActive(location.pathname);
  }, [location]);
  return (
    <>
      <div className="header center flex">
        <div className="flex align-center logoContent" style={{ marginTop: '30px' }}>
          <img className="logo" alt="logo" src="/static/img/logo.png" />
          <div className="logoName">
            图灵小镇<p>TURING TOWN</p>
          </div>
        </div>
        <ul className="nav flex align-center">
          <li
            className={active == '/' ? 'active' : ''}
            onClick={() => navTo('/')}
          >
            <span>{intl.get('Home')}</span>
          </li>
          <li
            className={active == '/about' ? 'active' : ''}
            onClick={() => navTo('/about')}
          >
            <span>{intl.get('TownProfile')}</span>
          </li>
          <li
            className={active.indexOf('/news') > -1 ? 'active' : ''}
            onClick={() => navTo('/news')}
          >
            <span>{intl.get('InfoCenter')}</span>
          </li>
          <li
            className={active.indexOf('/enter') > -1 ? 'active' : ''}
            onClick={() => navTo('/enter')}
          >
            <span>{intl.get('ScenicSpotTour')}</span>
          </li>
          <li
            className={active.indexOf('/activity') > -1 ? 'active' : ''}
            onClick={() => navTo('/activity')}
          >
            <span>{intl.get('Activity')}</span>
          </li>
          <li
            className={active.indexOf('/service') > -1 ? 'active' : ''}
            onClick={() => navTo('/service')}
          >
            <span>{intl.get('Enterprise')}</span>
          </li>
          <li onClick={info}>
            <span>{intl.get('Mall')}</span>
          </li>
          <li onClick={() => change()}>
            <span>
              {localStorage.getItem('locale') === 'zh' ? 'English / 日本語 / 한국어' : '中文'}
            </span>
          </li>
        </ul>
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Service.css';
import { ticket } from '../../js/api';
import { Form, Input, Button, message } from 'antd';
const { TextArea } = Input;

export default function ServiceDetail() {
  const [content, setContent] = useState('');
  const location = useLocation();
  let info = location.state;

  const [form] = Form.useForm();
  const onFinish = (values) => {
    var dataform = Object.assign({}, values);
    dataform.type = content;
    ticket(dataform).then((res) => {
      if (res.error_code) {
        message.error('提交失败');
      } else {
        message.success({
          content:
            '信息提交成功，小镇企服人员将在1-2个工作日与您取得联系（节假日除外）。如有任何疑问，可联系企服小助手（17300938755）',
          duration: 5,
          style: {
            marginTop: '30vh',
          },
        });
        form.resetFields();
      }
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  useEffect(() => {
    let search = location.search.split('?')[1];
    // init();
    if (search) {
      if (search === 'faq') setContent('投诉建议');
      else setContent(decodeURIComponent(search));
    } else {
      setContent(info.content);
    }
  }, []);
  return (
    <>
      <div className="header_img">
        <img
          className="header_bg"
          style={{ width: '100%', height: '300px', objectFit: 'cover' }}
          src="/static/header/企业服务.jpg"
          alt=""
        />
        <img
          src="/static/header/企业服务.png"
          alt=""
          className="header_title"
        ></img>
      </div>
      <div className="center form servicedetail">
        <div style={{ marginTop: '40px' }}>
          <div className="center-title text-center">
            <h3
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            ></h3>{' '}
            {/* <div className="lineText">
              <span>APPOINTMENT</span>
            </div> */}
          </div>{' '}
          <div className="call">图灵小镇一站式企服中心专线：0571-82201959</div>
        </div>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {content !== '投诉建议' ? (
            <Form.Item
              label="企业名称"
              name="name"
              rules={[
                {
                  required: true,
                  message: '请输入企业名称',
                },
              ]}
            >
              <Input placeholder="请输入企业名称" />
            </Form.Item>
          ) : (
            ''
          )}
          {content !== '投诉建议' ? (
            <Form.Item
              label="办公地址"
              name="address"
              rules={[
                {
                  required: true,
                  message: '请选择办公地址',
                },
              ]}
            >
              <Input placeholder="请输入办公地址" />
            </Form.Item>
          ) : (
            ''
          )}

          <Form.Item
            label="联系人姓名"
            name="applier"
            rules={[
              {
                required: true,
                message: '请输入联系人姓名',
              },
            ]}
          >
            <Input placeholder="请输入联系人姓名" />
          </Form.Item>

          <Form.Item
            label="联系人电话"
            name="applierMobile"
            rules={[
              {
                required: true,
                message: '请输入联系人电话',
              },
            ]}
          >
            <Input placeholder="请输入联系人电话" />
          </Form.Item>
          <Form.Item
            label={content === '投诉建议' ? '投诉意见' : '咨询需求'}
            name="mark"
          >
            <TextArea
              rows={4}
              placeholder={`请输入${
                content === '投诉建议' ? '投诉意见' : '咨询需求'
              }`}
              maxLength={300}
              showCount
            />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 11,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function () {
  const [info, setInfo] = useState({});
  const [list, setDetail] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setInfo(location.state && location.state.content);
    console.log(location.state.content);
    setDetail(location.state && location.state.content.detail);
  }, []);

  return (
    <>
      <div>
        <img
          style={{ width: '100%', height: '350px' }}
          src="/static/img/1bg.png"
          alt=""
        />
      </div>

      <div className="center detailList">
        <div className="top_lj">
          <a href="/">首页</a> &gt; <a href="/enter">景区导览</a> &gt;
          <strong> 周边推荐详情</strong>
        </div>
        <div className="text-center title">{info.title}</div>
        {list.map((v, i) => {
          return <div dangerouslySetInnerHTML={{ __html: v }} key={i}></div>;
        })}
      </div>
      {info.link ? (
        <div className="center detailList_link">
          <a href={info.link} target="_blank" rel="noreferrer">
            在线预订
          </a>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
export default function Col3() {
  const [obj, setObj] = useState({
    name: '北大信研院',
    desc: '北大信研院以北京大学为依托，以发展人工智能、智慧城市、智慧医疗、智能制造等未来数字经济产业核心技术为重点，围绕打造人机物融合的信息系统，广泛集聚全球顶尖人才团队，开展关键技术应用研究、集成创新，为数字经济发展提供技术支撑和产业政策咨询，促进科技成果转移转化，衍生孵化科技企业，构建创新水平与国际同步、研发活动与国际融合、体制机制与国际接轨的现代产业科技创新基地。',
  });
  const [show, setShow] = useState(false);
  let list = [
    {
      name: '北大信研院',
      desc: '北大信研院以北京大学为依托，以发展人工智能、智慧城市、智慧医疗、智能制造等未来数字经济产业核心技术为重点，围绕打造人机物融合的信息系统，广泛集聚全球顶尖人才团队，开展关键技术应用研究、集成创新，为数字经济发展提供技术支撑和产业政策咨询，促进科技成果转移转化，衍生孵化科技企业，构建创新水平与国际同步、研发活动与国际融合、体制机制与国际接轨的现代产业科技创新基地。',
    },
    {
      name: '浙大研究院',
      desc: '浙大研究院是集“前沿基础技术研发、工程技术及系统平台研发、创新创业企业孵化”于一体的新型研发机构，汇聚了宇链科技、水木物联、岭上数字、布鲁可人工智能等拥有尖端数字科技成果的企业，这里浓厚的创新氛围、强劲的科创实力、优质的产业生态，定会令你印象深刻。',
    },
    {
      name: '中国（杭州）5G创新谷',
      desc: '5G创新谷是按“一院一园一基金+专业机构运营”模式，以政企合作形式构建，引领5G产业发展的创业创新生态产业园区。通过浙江5G研究院、容腾5G产业基金等产业资源，聚集5G产业的软件开发、技术应用、终端生产一体化，打造5G产业创新发展高地。',
    },
    {
      name: 'ITC归谷国际中心',
      desc: 'ITC归谷国际中心是集商务办公、生活服务于一体的国际人才成长平台，引入了萧山国际人才服务中心、杭州全球青年人才中心、杭州（萧山）移民事务服务中心等政务服务平台，构成了“人才引入、项目培育、政务协同、本地融入”的良性产业人才生态圈。',
    },
    {
      name: '长三角（杭州）制造业数字化能力中心',
      desc: '能力中心是专门服务传统制造业数字化改造的“问诊”平台，以“产业数字化路由器，数字产业化新引擎”为总体定位，已精准引进近30家优质数字化转型服务机构，努力打造立足萧山、服务浙江、辐射长三角的制造业数字化能力供给核。      ',
    },
    {
      name: '杭州市信息安全产业园',
      desc: '杭州市信息安全产业园是全国第四，浙江省首个信息安全产业园。园区以网络安全、信息服务业为产业特色，通过“科技园区+孵化器+加速器+产业园”的功能布局，构建了基于1+3N，即一个公共服务平台 、三大服务核心 、九大增值服务板块服务体系的全要素生态型链式科技企业孵化器。',
    },
    {
      name: '中科宝盛科技园',
      desc: '中科宝盛科技园由中科院计算所浙江分所（暨萧山工业研究院）和宝盛控股集团合作共建，着力于打造一个集“政、产、学、研、金、介、用”于一体的高新技术科技孵化器。园区依托于中科院大平台，充分发挥中科院高科技人才和技术的优势，营造优质的创新创业环境。',
    },
    {
      name: '映想空间',
      desc: '映想空间是一家以文体产业为主导的新型孵化空间，设有天使投资基金3600万元。按照“政府引导、市场运作、政策支持”的原则，以“优化创业环境、完善创业服务、培育创业主体”为重点，通过影视文化和体育两大平台聚集专业人员和上下游资源，为企业和团队提供政策咨询、创业沙龙、品牌设计、新媒体运营、投融资顾问等服务。映想空间用专业孵化引入专业资源。',
    },
    {
      name: 'Will space联合办公',
      desc: 'Will space联合办公整体面积近7000方，入驻企业100余家，是一个集办公、孵化、社交等一体化创业与生活服务的多功能平台，采用“创业辅导+创业投资+社群服务平台”的特色孵化模式，为园区及入驻企业提供人才招聘、投融资、工商税务、法律法务和市场推广等服务，也为创业者提供一个工作、网络、社交和资源共享的空间。      ',
    },
    {
      name: '筑谷寺',
      desc: '筑谷寺众创空间是由华媒智谷独立自主运营的众创空间品牌，空间面积1458平方米，进一步聚焦数字基础、数字应用、文创科技、数字直播等领域，打造“众创空间——孵化器——加速器——产业基地——总部”垂直化孵化体系，以筑谷寺众创空间为支点，以华媒智谷旗下园区为支撑，将优秀毕业企业向更大的平台输送，实现入孵毕业企业无缝对接。',
    },
  ];
  const sel = (v) => setObj(v);

  return (
    <>
      <div className="center platform">
        <Swiper
          className="swiper-no-swiping"
          modules={[Autoplay]}
          slidesPerView={5}
          spaceBetween={20}
          loop={true}
          speed={2000}
          freeMode={true}
          autoplay={{
            delay: 1,
            disableOnInteraction: false,
          }}
        >
          {list.map((v, i) => {
            return (
              <SwiperSlide onClick={() => setObj(v)}>
                <img src={`/static/平台/${v.name}.png`} alt=""></img>
                <div className="name">{v.name}</div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <CSSTransition in={show} timeout={500} classNames="my-node">
          <div className={obj.name ? 'article active' : 'article hide'}>
            <div className="title">{obj.name}</div>
            <div className="desc">{obj.desc}</div>
          </div>
        </CSSTransition>
      </div>
    </>
  );
}

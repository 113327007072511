import React, { useState } from 'react'
import './About.css'
export default function Col2() {
  const [active, setActive] = useState(0)
  return (
    <>
      <ul className="center flex tabs">
        <li onClick={()=>setActive(0)} className={active==0?'active':''}>国博二期</li>
        <li onClick={()=>setActive(1)} className={active==1?'active':''}>EIC超级综合体</li>
        <li onClick={()=>setActive(2)} className={active==2?'active':''}>省交通集团金融中心</li>
        <li onClick={()=>setActive(3)} className={active==3?'active':''}>浙商银行</li>
      </ul>
      <div className='cardimglist' style={{'display':active==0?'block':'none'}}>
        <div className='flex cardimg center'>
          <img src="/static/img/img/1.jpg" alt="" />
          <div>杭州国际博览中心二期是全区“十四五”期间重点工程的标杆项目。项目位于钱江世纪城奥体博览城核心区，东至金鸡路、南至利二路、西至博奥路、北至平澜路，总用地131亩，总建筑面积约45.4万平方米。建筑高度160米，地下3层，地上29层。主体结构将采用钢结构建设，既有大跨度结构，也有超高层结构，其中钢结构最大单跨84米。作为钱江南岸的又一地标，国博二期项目建成后，将进一步提升钱江世纪城人气和城市魅力，对世纪城“建设新中心、发展新经济、打造新封面”具有重要意义。</div>
        </div>
        <div className='flex cardimg center'>
          <div>
            <p>国博二期主体工程EPC总承包项目由东南网架、华东建筑设计院、潮峰钢构组成的联合体中标。2022年4月8日，国博二期项目EPC总承包合同正式签署。</p>
            <p>项目设计突出简洁大气、端庄正气，强化与杭州国际博览中心一期项目的形态呼应与功能互补。例如，项目通过单元模块化的组合最大化满足展览功能；大尺度的悬挑顶冠，既满足高规格会议的仪式感，又与一期的悬挑形成相融的对话关系。同时，项目设置双层空中连廊，8米标高的人行通廊和16米标高的货运通道将一二期建筑融为一体。</p>
          </div>
          <img src="/static/img/img/2.jpg" alt="" />
        </div>
        <div className='flex cardimg center'>
          <img src="/static/img/img/3.jpg" alt="" />
          <div>作为G20国际博览中心的“姊妹篇”，国博二期将重点打造都市型会展，建成后国博一、二期珠联璧合，展览总面积将达15万平方米，会展场馆规模将跻身全国大型会展综合体前列，成为助力萧山区域发展的城市名片，为杭州打造国际会展之都注入强劲动力。</div>
        </div>
      </div>

      <div className='cardimglist' style={{'display':active==1?'block':'none'}}>
        <div className='flex cardimg center'>
          <img src="/static/img/img/7.png" alt="" />
          <div>
            <p>作为萧山区“4286”产业载体项目之一，EIC超级综合体项目总体量约50万平方米，是浙江建工集团继黄龙商圈EAC、未来科技城EFC之后在世纪城落子的第三城。项目由全球顶尖设计团队英国福斯特建筑事务所操刀设计，融合新金融、数字经济、医疗健康、科技创新等核心产业，打造拥有摩天楼宇集群、互联网总部IT&Mall、国际时尚商业街区的高端综合产业载体。对标美国西雅图，EIC致力于成为一个集聚全球资源及智慧、激发工程师创造力的超级综合体，吸引中概股回归以及数字经济头部企业、医疗健康大脑、新金融新科技企业入驻。未来，这里不仅仅是一座虹吸顶级企业和人才的超级微城市，也将是一个匹配顶级人才需求的“金融家社区”。</p>
            {/* <p>台泥是台湾的代表品牌之一，“环保、能源、水泥”是台泥三大核心事业，是台泥未来三大主轴。台泥不只是水泥制造与销售公司，而是主动寻求环保解决方案、保护自然环境的 Eco-Solution Provider，并朝着打造“零废弃、零污染、零排放”之循环经济，创造永续企业的新价值进行。</p>
            <p>项目位于杭州市钱江世纪城核心区块。东至潮韵路绿化带，北至规划支路，西至观澜路绿化带，南至金鸡路绿化带。规划总用地面积35.08亩。总建筑面积约177000平方米，其中地上建筑面积约117000平方米，建筑高度210米，拟建超高层办公大楼等;地下室共三层，建筑面积约60000平方米。容积率不大于5.0，建筑密度不大于50%，绿地率不小于25%。项目建成后将成为台泥杭州环保科技总部，专注水泥生产过程中二氧化碳减排与工业废弃物再利用等智慧环保领域的科技研发和技术攻坚。</p> */}
          </div>
        </div>
      </div>

      <div className='center cardimglist' style={{'display':active==2?'block':'none'}}>
        <div className="flex center" style={{'justifyContent':'center'}}>
          <img src="/static/img/img/5.png" className="mr20" alt="" style={{width:'400px'}} />
          <img src="/static/img/img/6.png" className="ml20" alt="" style={{width:'400px'}} />
        </div>
        <div className="mt30 mb30" style={{padding:'0 80px','lineHeight':'28px','textIndent':'2em'}}>浙江省交通集团金融中心总部项目位于钱江世纪城核心单元，项目总建筑面积16.3万平方米，容积率约4.0。其中，地上建筑面积11万平方米，地下建筑面积5.3万平方米，包括高层办公、商业和配套用房，计划总投资25亿元。省交通集团是浙江省综合交通投融资主平台和建设主力军，未来将充分发挥平台载体的集聚效应，带领并吸引更多金融企业落户钱江世纪城，助力世纪城金融产业发展。预计建成五年内，省交通集团下属企业在萧山区累计纳税额将达40亿元。</div>
      </div>

      <div className='cardimglist' style={{'display':active==3?'block':'none'}}>
        <div className='flex cardimg center'>
          <img src="/static/img/img/51.jpg" alt="" />
          <div>浙商银行钱江世纪城总部大楼项目位于杭州市萧山区钱江世纪城核心区块，东至潮韵路绿化带，南至规划支路，西至观澜路绿化带，北至民和路。根据规划，本项目位于钱江世纪城的钱塘江金融城，是钱塘江金融港湾核心区，将对标国际一流金融集聚区，以“产业金融”、“科技金融”思路，以金融科技、金融总部以及产业链金融为重点，努力打造具有强大资本吸纳能力、人才集聚能力、创新转化能力、服务辐射能力的“国际金融科技中心”。</div>
        </div>
        <div className='flex cardimg center'>
          <div>
            <p>作为“152”工程项目，位于钱江世纪城SJC0208-06地块，总用地面积60.01亩，总建筑面积约300040平方米，其中地上总建筑面积约200040平方米，地下建筑面积约100000平方米。总部项目主要服务浙商银行网络金融部、金融科技部、信息技术部、普惠金融部等核心部门办公及科技金融子公司入驻。项目的建设，是响应浙江“大湾区”建设、钱塘江金融港湾规划及杭州“拥江发展”战略极重要的一次布局，将为图灵小镇建设数字经济高地提供强力的金融支持。</p>
          </div>
          <img src="/static/img/img/52.jpg" alt="" />
        </div>
      </div>
    </>
  )
}

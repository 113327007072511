import React, { useState, useEffect } from 'react';
import { Input, Select, Pagination, Modal, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import './Activity.scss';
import { getActivityList, getActivityApply } from '../../js/api';
const { Option } = Select;

export default function Activity() {
  const [list, setList] = useState([]);
  const [type, setType] = useState('');
  const [keyword, setKeyword] = useState(''); //模糊搜索
  const [status, setStatus] = useState(''); //状态
  const [page, setPage] = useState(0); //页数
  const [size, setSize] = useState(10); //条数
  const [total, setTotal] = useState(0); //总数
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [name, setName] = useState(''); //姓名
  const [mobile, setMobile] = useState(''); //电话
  const [unit, setUnit] = useState(''); //单位
  const [activityId, setActivityId] = useState(0); //id
  var navigate = useNavigate();
  const sel = (v, i) => {
    navigate('/activitydetail', {
      state: {
        id: v.id,
      },
    });
  };
  // let keyword = '';
  // 分类切换
  const typeChange = (value) => {
    setType(value);
    console.log(value);
    // activityList();
  };
  //状态切换
  const statusChange = (value) => {
    setStatus(value);
  };
  //输入框
  const inputChange = (value) => setKeyword(value);
  var onShowSizeChange = (current, size) => {
    setPage(0);
    setSize(size);
  };
  var onChange = (page) => {
    console.log(page);
    setPage(page - 1);
  };

  const toSignUp = ({ id }) => {
    setActivityId(+id);
    setIsModalVisible(true);
  };
  const handleOk = () => {
    activityApply();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setMobile('');
    setName('');
    setUnit('');
  };

  const activityList = async () => {
    let res = await getActivityList({
      status: status === '' ? '' : +status,
      type,
      keyword,
      page,
      size,
    });
    setTotal(res.total);
    setList(res.data);
  };
  const formChange = async (e, str) => {
    if (str === 'name') setName(e);
    else if (str === 'mobile') setMobile(e);
    else if (str === 'unit') setUnit(e);
  };
  const activityApply = async () => {
    await getActivityApply(activityId, {
      activityId,
      name,
      mobile,
      unit,
    });
    message.success('报名成功！');
    handleCancel();
  };
  useEffect(() => {
    activityList();
  }, [page, size, type, status]);
  return (
    <>
      <div>
        <div className="header_img">
          <img
            className="header_bg"
            style={{ width: '100%', height: '300px', objectFit: 'cover' }}
            src="/static/header/特色活动.jpg"
            alt=""
          />
          <img
            src="/static/header/特色活动.png"
            alt=""
            className="header_title"
          ></img>
        </div>
        <div className="center search">
          <div className="item">
            <Select defaultValue={type} onChange={typeChange}>
              <Option value="">全部</Option>
              <Option value="图灵艺厅">图灵AI艺厅</Option>
              <Option value="图灵智汇">图灵AI智汇</Option>
              <Option value="图灵公益">图灵AI公益</Option>
              <Option value="图灵日">TwO图灵日</Option>
              <Option value="图灵讲堂">图灵e讲堂</Option>
              <Option value="图说新语厅">图说新语厅</Option>
            </Select>
          </div>
          <div className="item">
            <Select defaultValue={status} onChange={statusChange}>
              <Option value="">全部状态</Option>
              <Option value="0">正在报名</Option>
              <Option value="1">进行中</Option>
              <Option value="2">已结束</Option>
            </Select>
          </div>
          <div className="item">
            <Input
              placeholder="请输入内容"
              onChange={(e) => {
                inputChange(e.target.value);
              }}
            />
          </div>
          <div className="btn" onClick={() => activityList()}>
            搜索
          </div>
        </div>
        <div className="activity-list">
          <ul className="itemList center">
            {list.map((e, i) => {
              return (
                <li onClick={() => sel(e)} key={i}>
                  <div className="img">
                    <img src={e.image} width="184" height="257" />
                    <div class="tip">
                      <i></i>
                      {e.status === 2
                        ? '已结束'
                        : e.status === 1
                          ? '进行中'
                          : '未开始'}
                    </div>
                  </div>
                  <div className="info">
                    <div className="title">
                      <a>{e.name}</a>
                    </div>
                    <div
                      className="txt"
                      dangerouslySetInnerHTML={{ __html: e.detail }}
                    ></div>
                    <div className="dl">
                      <div className="dt t">
                        {/* <i className="el-icon-time"></i>{' '} */}
                        <img src="/static/img/time.png" alt="" />
                        活动时间
                      </div>
                      <div className="dd">
                        {e.startTime} ~ {e.endTime}
                      </div>
                    </div>
                    <div className="dl">
                      <div className="dt a">
                        <img src="/static/img/address.png" alt="" />
                        活动地址
                      </div>
                      <div className="dd">{e.address}</div>
                    </div>
                    {e.status === 2 ? (
                      <div className="btn">已结束</div>
                    ) : e.status === 1 ? (
                      <div className="btn">进行中</div>
                    ) : (
                      <div
                        className=" btn toSignUp"
                        onClick={(v) => {
                          v.stopPropagation();
                          toSignUp(e);
                        }}
                      >
                        点击报名
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="center pagination">
          <Pagination
            current={page + 1}
            showQuickJumper
            total={total}
            onChange={onChange}
            onShowSizeChange={onShowSizeChange}
            showTotal={(total) => `共${total}条`}
            defaultPageSize={size}
            defaultCurrent={page}
          />
        </div>
      </div>
      <Modal
        wrapClassName="signUpModel"
        title="报名"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        onChange={onChange}
      >
        <p>
          姓名
          <Input
            value={name}
            placeholder="请输入姓名"
            onChange={(e) => {
              formChange(e.target.value, 'name');
            }}
          />
        </p>
        <p>
          电话
          <Input
            value={mobile}
            placeholder="请输入电话"
            onChange={(e) => {
              formChange(e.target.value, 'mobile');
            }}
          />
        </p>
        <p>
          单位：
          <Input
            value={unit}
            placeholder="请输入单位"
            onChange={(e) => {
              formChange(e.target.value, 'unit');
            }}
          />
        </p>
      </Modal>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { Input, Modal, Tabs, message } from 'antd';
import './Activity.scss';
import { useLocation } from 'react-router-dom';
import {
  getActivityDetail,
  getActivityApply,
  getActivityList,
} from '../../js/api';
const { TabPane } = Tabs;

export default function ActivityDetail() {
  const [list, setList] = useState([]);
  const [info, setInfo] = useState({});
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [name, setName] = useState(''); //姓名
  const [mobile, setMobile] = useState(''); //电话
  const [unit, setUnit] = useState(''); //单位
  const activityDetail = async () => {
    let res = await getActivityDetail(location.state.id);
    setInfo(res.data);
  };
  const activityApply = async () => {
    await getActivityApply(location.state.id, {
      activityId: +location.state.id,
      name,
      mobile,
      unit,
    });
    message.success('报名成功！');
    handleCancel();
  };
  const activityList = async () => {
    let res = await getActivityList({
      status: '',
      type: '',
      keyword: '',
      page: 0,
      size: 3,
    });
    setList(res.data);
  };
  const formChange = async (e, str) => {
    if (str === 'name') setName(e);
    else if (str === 'mobile') setMobile(e);
    else if (str === 'unit') setUnit(e);
  };
  const toSignUp = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    activityApply();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setMobile('');
    setName('');
    setUnit('');
  };
  useEffect(() => {
    activityDetail();
    activityList();
    // activityApply();
  }, []);
  return (
    <>
      <div className="activity-detail">
        <div className="act-head center">
          <div className="img">
            <img
              className="img_1"
              src={info && info.image}
              width="184"
              height="257"
            />
            <div className="tip">
              <i></i>
              {+new Date() > +new Date(info.endTime)
                ? '已结束'
                : +new Date() < +new Date(info.startTime)
                ? '未开始'
                : '进行中'}
            </div>
          </div>
          <div className="intro">
            <div className="title">{info.name}</div>
            <div className="tips">
              {info.tag
                ? info.tag.split(',').map((v, i) => {
                    return <span key={i}>{v}</span>;
                  })
                : ''}
            </div>
            <div className="list">
              <div className="item">
                <div className="dt">活动</div>
                {info.startTime} ~ {info.endTime}
              </div>
              {info.applyStartTime && info.applyEndTime ? (
                <div className="item">
                  <div className="dt">报名</div>
                  {info.applyStartTime} ~ {info.applyEndTime}
                </div>
              ) : (
                ''
              )}
              <div className="item w100">
                <div className="dt">地址</div>
                {info.address}
              </div>
              <div className="item w100">
                <div className="dt">人数</div>已报名{info.applyCount || 0}人
                {/* / 不限人数 */}
              </div>
            </div>
          </div>
          {/* <div className="btn">已结束</div> */}
          {+new Date() > +new Date(info.endTime) ? (
            <div className="btn">已结束</div>
          ) : +new Date() < +new Date(info.startTime) ? (
            <div className="btn">未开始</div>
          ) : (
            <div
              className=" btn toSignUp"
              onClick={(v) => {
                v.stopPropagation();
                toSignUp();
              }}
            >
              点击报名
            </div>
          )}
        </div>
        <div className="act-body center">
          <div className="block-main">
            <Tabs defaultActiveKey="1">
              <TabPane tab="活动详情" key="1">
                <div
                  className="block-content"
                  dangerouslySetInnerHTML={{ __html: info && info.detail }}
                ></div>
              </TabPane>
              <TabPane tab="活动回顾" key="2">
                <div
                  className="block-content"
                  dangerouslySetInnerHTML={{ __html: info && info.review }}
                ></div>
              </TabPane>
            </Tabs>
          </div>

          {/* <div className="block-side">
            <div className="title">推荐活动</div>
            {list.map((v, i) => {
              return (
                <div className="item">
                  <div className="num">{i + 1}</div>
                  <div className="txt">{v.name}</div>
                </div>
              );
            })}
          </div> */}
        </div>

        {/* <ul className="itemList center">
          <li>
            <div className="img">
              <a>
                <img
                  src="/static/img/activity_2.jpeg"
                  width="184"
                  height="257"
                />
              </a>
            </div>
            <div className="info">
              <div className="title">
                <a>
                  免费赠票丨好运星期一，图灵小镇话剧门票限时免费领，仅限40张
                </a>
              </div>

              <div className="dl">
                <div className="dt t">
                  <i className="el-icon-time"></i> 活动时间
                </div>
                <div className="dd">
                  2022年2月24日（周四）19:30 ~ 2022年2月25日（周五）19:30
                </div>
              </div>
              <div className="dl">
                <div className="dt a">
                  <i className="el-icon-location-outline"></i> 活动地址
                </div>
                <div className="dd">
                  浙江省·杭州市·萧山区&nbsp;&nbsp;图灵小镇
                </div>
              </div>
              <div className="btn">已结束</div>
            </div>
          </li>
        </ul> */}
      </div>
      <Modal
        wrapClassName="signUpModel"
        title="报名"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          姓名
          <Input
            value={name}
            placeholder="请输入姓名"
            onChange={(e) => {
              formChange(e.target.value, 'name');
            }}
          />
        </p>
        <p>
          电话
          <Input
            value={mobile}
            placeholder="请输入电话"
            onChange={(e) => {
              formChange(e.target.value, 'mobile');
            }}
          />
        </p>
        <p>
          单位：
          <Input
            value={unit}
            placeholder="请输入单位"
            onChange={(e) => {
              formChange(e.target.value, 'unit');
            }}
          />
        </p>
      </Modal>
    </>
  );
}

import axios from 'axios';
let _baseUrl = 'https://www.turingtown.com/api/v1';

const formatForm = function (data) {
  let formdata = new FormData();
  for (let k in data) {
    formdata.append(k, data[k]);
  }
  return formdata;
};

const formatJSON = function (data) {
  let param = '?';
  for (let k in data) {
    param += k + '=' + data[k] + '&';
  }
  return param;
};

export function getCateList(data) {
  const url = _baseUrl + '/content/article/query';
  return axios.post(url, data).then((res) => {
    return Promise.resolve(res.data);
  });
}

export function getCompanyList(data) {
  const url = _baseUrl + '/content/company/query';
  return axios.post(url, data).then((res) => {
    return Promise.resolve(res.data);
  });
}

export function getServiceList(data) {
  const url = _baseUrl + '/content/service/query';
  return axios.post(url, data).then((res) => {
    return Promise.resolve(res.data);
  });
}

export function apply(data) {
  const url = _baseUrl + '/content/apply';
  return axios.post(url, data).then((res) => {
    return Promise.resolve(res.data);
  });
}

export function ticket(data) {
  const url = _baseUrl + '/content/ticket';
  return axios.post(url, data).then((res) => {
    return Promise.resolve(res.data);
  });
}
/**
 *
 * 获取活动
 */
export function getActivityList(data) {
  const url = _baseUrl + '/content/activity/query';
  return axios.post(url, data).then((res) => {
    return Promise.resolve(res.data);
  });
}

export function getActivityDetail(id) {
  const url = _baseUrl + `/content/activity/${id}`;
  return axios.get(url).then((res) => {
    return Promise.resolve(res.data);
  });
}

export function getActivityApply(id, data) {
  const url = _baseUrl + `/content/activity/${id}/apply`;
  return axios.post(url, data).then((res) => {
    return Promise.resolve(res.data);
  });
}
export function article(id, data) {
  const url = _baseUrl + `/content/article/${id}`;
  return axios.get(url, { params: data }).then((res) => {
    return Promise.resolve(res.data);
  });
}

export function getBanner(data) {
  const url = _baseUrl + `/config/banner`;
  return axios.get(url).then((res) => {
    return Promise.resolve(res.data);
  });
}



export function adBanner1(data) {
  const url = _baseUrl + `/config/adBanner1`;
  return axios.get(url).then((res) => {
    return Promise.resolve(res.data);
  });
}


export function adBanner2(data) {
  const url = _baseUrl + `/config/adBanner2`;
  return axios.get(url).then((res) => {
    return Promise.resolve(res.data);
  });
}
export function adBanner3(data) {
  const url = _baseUrl + `/config/adBanner3`;
  return axios.get(url).then((res) => {
    return Promise.resolve(res.data);
  });
}
